<template>
  <div>
    <!-- 头部条 -->
    <div class="qing"></div>
    <!-- 菜单栏 -->
    <div style="">
      <el-tabs :tab-position="tabPosition">
        <el-tab-pane label="首页">
          <!-- 最大盒子 -->
          <div class="biggest">
            <!-- <div>
                    <el-breadcrumb separator="">
                        <el-breadcrumb-item :to="{ path: '/Learnonline/Homep' }">首页</el-breadcrumb-item>
                    </el-breadcrumb>
                </div> -->
            <!-- 左边盒子 -->
            <div class="leftbox">
              <!-- 时间日期、我的工单、待办事项 -->
              <div style="background-color: #ffff">
                <el-row :gutter="20">
                  <el-col :span="11"
                    ><div class="grid-content bg-purple">
                      <div class="qwe" style="cursor: pointer">
                        <span class="date">2021年01月12日</span>
                        <span class="day">星期二</span>
                      </div>
                    </div></el-col
                  >
                  <el-col :span="7"
                    ><div class="grid-content bg-purple">
                      <div class="Workorder" style="cursor: pointer">
                        <div style="float: left">
                          <img src="@/assets/gd.png" alt="" />
                        </div>
                        <div class="asdq">我的工单</div>
                        <div
                          style="
                            margin-left: 15px;
                            float: left;
                            font-size: 44px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #f55e5b;
                          "
                        >
                          2
                        </div>
                      </div>
                    </div></el-col
                  >
                  <el-col :span="6"
                    ><div class="grid-content bg-purple">
                      <div class="qianming">
                        <div style="float: left; margin-left: 30px">
                          <img src="@/assets/daiban.png" alt="" />
                        </div>

                        <div
                          style="
                            float: left;
                            margin-left: 18px;
                            color: #414141;
                            font-size: 24px;
                            font-weight: 400;
                            margin-top: 14px;
                          "
                        >
                          待办事项
                        </div>
                      </div>
                    </div></el-col
                  >
                </el-row>
                <!-- 时间日期、我的工单、待办事项 over -->
              </div>
              <!-- 常用功能 -->
              <div class="qe">
                <div class="common">
                  <span style="margin-left: 17px"> 常用功能 </span>
                </div>
                <div class="cler">
                  <ul>
                    <li title="学期管理">
                      <router-link to="/Learnonline/Homep">
                        <i
                          class="el-icon-s-management"
                          style="font-size: 23px"
                        ></i>
                        <span
                          style="
                            font-size: 22px;
                            font-weight: 400;
                            color: #414141;
                            line-height: 28px;
                          "
                          >学期管理</span
                        >
                      </router-link>
                    </li>
                    <li title="学期管理">
                      <router-link to="/Learnonline/Homep">
                        <i
                          class="el-icon-s-management"
                          style="font-size: 23px"
                        ></i>
                        <span
                          style="
                            font-size: 22px;
                            font-weight: 400;
                            color: #414141;
                            line-height: 28px;
                          "
                          >学期管理</span
                        >
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- 常用功能 over -->
              <!-- 教学数据 学生人数情况 -->
              <div class="jaio">
                <!-- 教学数据 -->
                <div class="jiaoxue">
                  <p
                    style="
                      padding-left: 33px;
                      margin-top: 24px;
                      margin-bottom: 16px;
                      text-align: center;
                      font-size: 22px;
                      font-weight: 600;
                      color: #2a2a2a;
                    "
                  >
                    教学数据
                  </p>
                  <el-row :gutter="20">
                    <el-col :span="8"
                      ><div class="grid-content bg-purple">
                        <div class="teachitem">
                          <img
                            src="@/assets/001.png"
                            alt=""
                            style="
                              width: 64px;
                              height: 64px;
                              margin-top: 32px;
                              float: left;
                            "
                          />
                          <p class="itemcount">38</p>
                          <p class="itemname">教师</p>
                        </div>
                      </div></el-col
                    >

                    <el-col :span="8"
                      ><div class="grid-content bg-purple">
                        <div class="teachitem">
                          <img
                            src="@/assets/002.png"
                            alt=""
                            style="
                              width: 64px;
                              height: 64px;
                              margin-top: 32px;
                              float: left;
                            "
                          />
                          <p class="itemcount">35</p>
                          <p class="itemname">管理员</p>
                        </div>
                      </div></el-col
                    >
                    <el-col :span="8"
                      ><div class="grid-content bg-purple">
                        <div class="teachitem">
                          <img
                            src="@/assets/003.png"
                            alt=""
                            style="
                              width: 64px;
                              height: 64px;
                              margin-top: 32px;
                              float: left;
                            "
                          />
                          <p class="itemcount">31</p>
                          <p class="itemname">课程</p>
                        </div>
                      </div></el-col
                    >
                  </el-row>
                </div>
                <!-- 学生人数情况 -->
                <div class="renshu">
                  <p
                    style="
                      padding-left: 33px;
                      margin-top: 24px;
                      margin-bottom: 16px;
                      text-align: center;
                      font-size: 22px;
                      font-weight: 600;
                      color: #2a2a2a;
                    "
                  >
                    学生人数情况
                  </p>
                  <el-row :gutter="20">
                    <el-col :span="12"
                      ><div class="grid-content bg-purple">
                        <div class="teachitem">
                          <img
                            src="@/assets/004.png"
                            alt=""
                            style="
                              width: 64px;
                              height: 64px;
                              margin-top: 32px;
                              float: left;
                            "
                          />
                          <p class="itemcount">2511</p>
                          <p class="itemname">在籍人数</p>
                        </div>
                      </div></el-col
                    >

                    <el-col :span="12"
                      ><div class="grid-content bg-purple">
                        <div class="teachitem">
                          <img
                            src="@/assets/005.png"
                            alt=""
                            style="
                              width: 64px;
                              height: 64px;
                              margin-top: 32px;
                              float: left;
                            "
                          />
                          <p class="itemcount">14</p>
                          <p class="itemname">毕业人数</p>
                        </div>
                      </div></el-col
                    >
                  </el-row>
                </div>
              </div>
              <!-- 教学数据 学生人数情况 over -->
              <!-- 人数统计echarts统计图 -->
              <div style="background-color: #fff; margin-bottom: 15px">
                <div
                  id="myChart"
                  style="width: 1330px; height: 400px; top: 25px"
                ></div>
              </div>
              <!-- 人数统计echarts统计图 over -->
              <!-- 年级注册情况\平台登录情况  -->
              <div
                style="
                  background-color: #fff;
                  margin-bottom: 15px;
                  padding-top: 20px;
                "
              >
                <!-- 左侧年级注册情况 -->
                <div style="width: 50%; display: inline-block">
                  <h2 style="margin-left: 15px">年级注册情况</h2>

                  <div style="width: 50%; display: inline-block">
                    <h3 style="margin-top: 10px; margin-left: 15px">
                      录取总人数:21人
                    </h3>
                    <div
                      id="myChart1"
                      style="
                        width: 200px;
                        height: 350px;
                        top: 25px;
                        display: inline-block;
                      "
                    ></div>
                  </div>

                  <div style="width: 50%; display: inline-block">
                    <h3 style="margin-top: 10px; margin-left: 15px">
                      招生总人数:87人
                    </h3>
                    <div
                      id="myChart2"
                      style="
                        width: 200px;
                        height: 350px;
                        top: 25px;
                        display: inline-block;
                      "
                    ></div>
                  </div>
                </div>
                <!-- 左侧年级注册情况 over -->

                <!-- 右边平台登录情况 -->
                <div style="width: 50%; display: inline-block">
                  <div
                    id="myChart3"
                    style="
                      width: 580px;
                      height: 350px;
                      bottom: 16px;
                      display: inline-block;
                    "
                  ></div>
                </div>
                <!-- 右边平台登录情况 over-->
              </div>
              <!-- 年级注册情况\平台登录情况  over-->

              <!-- 年度缴费情况 分布统计 -->
              <div
                style="
                  background-color: #fff;
                  margin-bottom: 15px;
                  padding-top: 20px;
                "
              >
                <div style="width: 50%; display: inline-block">
                  <div
                    id="myChart4"
                    style="
                      width: 580px;
                      height: 350px;
                      bottom: 16px;
                      display: inline-block;
                    "
                  ></div>
                </div>
                <div style="width: 50%; display: inline-block"></div>
              </div>
              <!-- 年度缴费情况 分布统计 over -->
            </div>
            <!-- 右边盒子 -->
            <div class="rightbox">
              <div></div>
            </div>
          </div>
          <!-- 最大盒子 over -->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<style  scoped>
.qing {
  width: 100%;
  height: 3rem;
  background-color: skyblue;
}
.biggest {
  width: 100%;
  background-color: #efefef;
}
.leftbox {
  display: inline-block;
  width: 68%;
  text-align: left;
  margin-top: 25px;
  /* 测试写背景扩展 */
  /* padding-bottom: 222px; */
}
.qwe {
  display: inline-block;
  /* padding-bottom: 0px; */
  padding-bottom: 5px;
  padding-top: 11px;
}
.rightbox {
  display: inline-block;
  width: 30%;
  float: right;
  padding-bottom: 12px;
}
.Workorder {
  display: inline-block;
  /* float: right; */
  padding: 0 20px;
  margin-left: 80px;
  /* border: 2px solid #ded9d9; */
  border-left: 2px solid #ded9d9;
  border-right: 2px solid #ded9d9;
}
.date {
  font-size: 34px;
  font-weight: 500;
  color: #5689f4;
  line-height: 48px;
  vertical-align: middle;
  margin-left: 17px;
}
.day {
  margin-left: 21px;
  vertical-align: middle;
  font-size: 22px;
  font-weight: 400;
  color: #576cff;
  line-height: 30px;
}
.asdq {
  float: left;
  margin-left: 18px;
  color: #414141;
  font-size: 24px;
  font-weight: 400;
  margin-top: 14px;
}
.el-col-5 {
  width: none !important;
}
.qe {
  min-width: 940px;
  background: #fff;
  padding: 10px 16px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.common {
  font-weight: 600;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 22px;
}
.cler {
  /* zoom: 1; */
  display: inline-block;
  margin-left: 50px;
}
li {
  float: left;
  /* width: 16.66%; */
  margin-bottom: 27px;
  font-size: 18px;
  /* font-family: PingFangSC-Regular,PingFang SC; */
  font-weight: 400;
  color: #414141;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 70px;
}
a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.jaio {
  margin-bottom: 10px;
  min-width: 940px;
}
.jiaoxue {
  width: 59%;
  padding-right: 5px;
  display: inline-block;
}
.renshu {
  width: 40%;
  padding-left: 5px;
  display: inline-block;
}
.teachitem {
  height: 128px;
  background-color: #fff;
  padding-left: 54px;
  overflow: hidden;
  /* min-width: 245px; */
}
.itemcount {
  font-size: 30px;
  padding-top: 30px;
  line-height: 42px;
}
.itemname {
  font-size: 18px;
  margin-top: 1px;
}
p {
  padding-left: 88px;
}
</style>

<script>
export default {
  data() {
    return {
      tabPosition: "left",
    };
  },
  mounted() {
    this.drawLine();
    this.drawLine1();
    this.drawLine2();
    this.drawLine3();
    this.drawLine4();
  },
  methods: {
    // 人数统计echarts统计图
    drawLine() {
      var myChart = this.$echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        // 设置颜色
        color: ["#9400D3", "#0000FF", "#FFA500"],
        // 标题
        title: { text: "学生数据统计" },
        legend: {},
        tooltip: {},
        dataset: {
          // 数据设置
          source: [
            ["product", "高起本", "高起专", "专升本"],
            ["2020级总人数：1019", 8, 12, 999],
            ["2019级总人数：1228", 1227, 1, 0],
            ["2018级总人数：264", 0, 263, 1],
            // ["Walnut Brownie", 72.4, 53.9, 39.1],
          ],
        },
        xAxis: { type: "category" },
        yAxis: {
          type: "value",
          min: 0,
          max: 1400,
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }],
      });
    },

    //  年级注册情况
    // 图一
    drawLine1() {
      var myChart = this.$echarts.init(document.getElementById("myChart1"));
      myChart.setOption({
        // 标题
        // title: { text: "年级注册情况" },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          // orient: "vertical",
          // left: 10,
          data: ["高起专", "高起本", "专升本"],
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 6, name: "高起专" },
              { value: 12, name: "高起本" },
              { value: 3, name: "专升本" },
            ],
          },
        ],
      });
    },
    // 图二
    drawLine2() {
      var myChart = this.$echarts.init(document.getElementById("myChart2"));
      myChart.setOption({
        // 标题
        // title: { text: "学生数据统计" },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          data: ["高起专", "高起本", "专升本"],
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 29, name: "高起专" },
              { value: 31, name: "高起本" },
              { value: 27, name: "专升本" },
            ],
          },
        ],
      });
    },

    // 平台登录情况
    drawLine3() {
      var myChart = this.$echarts.init(document.getElementById("myChart3"));
      myChart.setOption({
        // 标题
        // title: { text: "学生数据统计" },
        title: {
          text: "平台登录情况",
          // subtext: "纯属虚构",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["学生登录", "教师登录"],
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            dataView: { readOnly: false },
            magicType: { type: ["line", "bar"] },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "2021/01/09",
            "2021/01/10",
            "2021/01/11",
            "2021/01/12",
            "20201/01/13",
            "2021/01/14",
            "2021/01/15",
          ],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} °C",
          },
        },
        series: [
          {
            name: "学生登录",
            type: "line",
            data: [1, 4, 6, 0, 3, 0, 0],
          },

          {
            name: "教师登录",
            type: "line",
            data: [0, 0, 2, 2, 0, 0, 0],
          },
        ],
      });
    },
    // 年度缴费情况
    drawLine4() {
      var myChart = this.$echarts.init(document.getElementById("myChart4"));
      myChart.setOption({
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: ["已缴费", "未交费"],
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              { value: 333, name: "已缴费" },
              { value: 888, name: "未交费" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
  },
};
</script>