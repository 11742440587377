<template>
  <div class="big">
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <div class="main">
      <Left></Left>
      <router-view></router-view>
      <div class="mainright">
        <div style="border-color: #ddd;">
          <div class="clearfix">
            <h3 class="ampanel">
              <i class="el-icon-s-fold"></i>
              考试信息
            </h3>
          </div>
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="考试信息" name="first">
              <div class="amform">
                <div class="bankuai">
                  课程名称/编号：
                  <el-input v-model="input" placeholder="请输入内容"></el-input>
                </div>
                <el-button type="success">查询</el-button>
                <el-button type="success">导出考试信息</el-button>
              </div>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="date" label="序号" width="100"></el-table-column>
                <el-table-column prop="name" label="课程名称" width="160"></el-table-column>
                <el-table-column prop="province" label="考试形式" width="150"></el-table-column>
                <el-table-column prop="city" label="考试类型" width="130"></el-table-column>
                <el-table-column prop="city" label="场次" width="150"></el-table-column>
                <el-table-column prop="city" label="考试开始时间" width="120"></el-table-column>
                <el-table-column prop="city" label="考试结束时间" width="100"></el-table-column>
                <el-table-column prop="city" label="考场" width="140"></el-table-column>
                <el-table-column prop="city" label="地点" width="140"></el-table-column>
                <el-table-column prop="city" label="座位号" width="140"></el-table-column>
                <el-table-column prop="city" label="状态" width="140"></el-table-column>
              </el-table>

              <div class="block" style="padding:0.1rem;">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage1"
                  :page-size="100"
                  layout="total, prev, pager, next"
                  :total="1000"
                ></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="免试申请" name="second">
              <div class="amtext">
                <button @click=" dialogFormVisible = true">添加</button>
                <button>删除</button>
              </div>
              <el-dialog
                :visible.sync="dialogFormVisible"
                center
                :append-to-body="true"
                :lock-scroll="false"
                :width="dialogWidth"
              >
                <!--用户名登录表单-->
                <div>
                  <h2 style="padding:0.1rem;text-align:center;font-weight:500;">新增免试申请</h2>

                  <div class="namer">
                    *选择免试课程：
                    <el-select v-model="value" placeholder="请选择">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="namer">
                    <el-upload
                      class="upload-demo"
                      action="https://jsonplaceholder.typicode.com/posts/"
                      :on-change="handleChange"
                      :file-list="fileList"
                    >
                      <el-button size="small" type="primary">点击上传</el-button>
                      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                  </div>
                  <div class="namer">
                    <el-button type="success">提交</el-button>
                    <el-button type="success">取消</el-button>
                  </div>
                </div>
              </el-dialog>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="date" label="序号" width="100"></el-table-column>
                <el-table-column prop="name" label="课程" width="200"></el-table-column>
                <el-table-column prop="province" label="提交时间" width="170"></el-table-column>
                <el-table-column prop="city" label="审核状态" width="130"></el-table-column>
                <el-table-column prop="city" label="审核意见" width="165"></el-table-column>
                <el-table-column prop="city" label="附件" width="170"></el-table-column>
                <el-table-column prop="city" label="操作" width="100"></el-table-column>
              </el-table>

              <div class="block" style="padding:0.1rem;">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage1"
                  :page-size="100"
                  layout="total, prev, pager, next"
                  :total="1000"
                ></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="考试预约" name="three">
              <div class="amform">
                <div class="bankuai">
                  课程名称/编号：
                  <el-input v-model="input" placeholder="请输入内容"></el-input>
                </div>
                <el-button type="success">查询</el-button>
              </div>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="date" label="序号" width="100"></el-table-column>
                <el-table-column prop="name" label="操作" width="160"></el-table-column>
                <el-table-column prop="province" label="课程名称" width="150"></el-table-column>
                <el-table-column prop="city" label="课程编号" width="130"></el-table-column>
                <el-table-column prop="city" label="考试批次" width="150"></el-table-column>
                <el-table-column prop="city" label="场次" width="120"></el-table-column>
                <el-table-column prop="city" label="考试开始时间" width="100"></el-table-column>
                <el-table-column prop="city" label="考试结束时间" width="140"></el-table-column>
              </el-table>
              <div class="block" style="padding:0.1rem;">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage1"
                  :page-size="100"
                  layout="total, prev, pager, next"
                  :total="1000"
                ></el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- <div>
        <Footer></Footer>
        <router-view></router-view>
    </div>-->
  </div>
</template>
<style  scoped>
.amtext {
  display: inline-block;
  float: right;
  margin-right: 0.1rem;
}
.amtext button {
  padding-left: 0.1rem;
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
  padding-right: 0.1rem;
  background-color: #77b723;
  border: #77b723;
  color: #fff;
  border-radius: 0.05rem;
  margin-left: 0.1rem;
}
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.mainright {
  width: 77.8%;
  border: 1px solid transparent;
  border-color: #ddd;
  float: right;
  margin-left: 0.4rem;
}
.clearfix {
  color: #444;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 0.15rem;
}
.ampanel {
  font-size: 0.15rem;
  text-align: left;
}
.el-button--success {
  background-color: #77b723;
  border-color: #77b723;
}
.el-button--text {
  color: #77b723;
}
.el-pagination {
  white-space: normal;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
.bankuai {
  display: inline-block;
  width: 40%;
  font-size: 0.13rem;
}

.zhuangtai {
  display: inline-block;
  margin-right: 0.1rem;
}
.leixing {
  display: inline-block;
}
.fabu {
  display: inline-block;
}
.zhi {
  display: inline-block;
}
@media only screen and (max-width: 1100px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-left {
    width: 100% !important;
    margin-bottom: 0.2rem !important;
  }
  .mainright {
    width: 100% !important;
  }
  .el-input {
    width: 50% !important;
  }
  .el-menu-item {
    font-size: 0.3rem !important;
  }
  .header {
    font-size: 0.3rem !important;
    padding: 0.4rem;
  }
  .dp[data-v-1aa8c6e6] {
    display: inline-block;
    font-size: 0.3rem;
    float: left;
    margin-top: 0.09rem;
  }
  .sou[data-v-1aa8c6e6] {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong[data-v-1aa8c6e6] {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
  .bankuai {
    width: 100% !important;
  }
  .zhuangtai {
    margin-bottom: 0.1rem !important;
  }
  .leixing {
    margin-bottom: 0.1rem !important;
  }
  .fabu {
    margin-bottom: 0.1rem !important;
  }
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}

.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 70%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
.amform {
  margin-left: 0.2rem;
  /* margin-right: 0.2rem; */
  /* margin-top: 0.2rem; */
  /* margin-bottom: 0.2rem; */
  font-size: 0.12rem;
  text-align: left;
}
.namer {
  margin-top: 0.2rem;
  text-align: center;
}
</style>
<script>
import Header from "@/components/Backstage";
import Left from "@/components/Backlf";
// import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Left
    // Footer
  },
  data() {
    return {
      fileList: [
        {
          name: "food.jpeg",
          url:
            "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100"
        },
        {
          name: "food2.jpeg",
          url:
            "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100"
        }
      ],

      dialogWidth: 0,
      activeName: "first",
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      value1: "",
      value2: "",
      value: "",
      input: "",
      dialogFormVisible: false,
      dialogVisible: false,
      dialogVisible1: false,
      dialogFormVisible3: false,
      currentPage1: 4,
      currentPage3: 2,
      form: {
        name: "",

        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      region: "",
      region1: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1517 弄",
          zip: 200333
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1519 弄",
          zip: 200333
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1516 弄",
          zip: 200333
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1516 弄",
          zip: 200333
        }
      ]
    };
  },
  created() {
    this.setDialogWidth();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    setDialogWidth() {
      var val = document.body.clientWidth;
      const def = 450; // 默认宽度
      if (val < def) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = def + "px";
      }
    },
    handleClick(tab, event) {
    },
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
    // onSubmit() {
    //   this.dialogVisible = false;
    // },
    // handleClick() {
    //   this.dialogFormVisible3 = true;
    // },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  }
};
</script>



