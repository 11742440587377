<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        考生安排进度
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary">导出</el-button>
            <!-- <el-button icon="el-icon-setting" type="primary" @click="reshuffle()"
              >设置学籍异动（层次和专业）</el-button
            > -->
            <!-- <el-button icon="el-icon-check" type="primary">审核</el-button> -->
            <!-- <el-button icon="el-icon-close" type="primary">驳回</el-button> -->
          </el-form-item>
          <div>
             <el-form-item style="margin-bottom:0;margin-left:2rem">
            批次
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
           <el-form-item style="margin-bottom:0;margin-left:2rem">
            函授站
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border>
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <!-- <el-table-column label="序号" width="100" prop="id"></el-table-column> -->
          <el-table-column label="考试批次" prop="name">
            <!-- <template>
              <div style="color: #1890ff; cursor: pointer" @click="testscope()">添加考试范围</div>
            </template> -->
          </el-table-column>
          <el-table-column label="函授站名称" prop="name">
            <!-- <template>
              <div style="color: #1890ff; cursor: pointer" @click="examine()">查看考试课程</div>
            </template> -->
          </el-table-column>
          <el-table-column label="函授站代码" prop="sex"> </el-table-column>
          <el-table-column label="总预约人数" prop="sex"> </el-table-column>
          <el-table-column label="实际安排人数" prop="sex"> </el-table-column>
          <el-table-column label="未安排人数" prop="sex"> </el-table-column>
          <el-table-column label="详情" prop="sex" width="200"> </el-table-column>
          <!-- <template>
              <div style="color: #1890ff; cursor: pointer" @click="sessions()">设置场次</div>
            </template> -->
          <!-- <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" @click="reshuffle(scope.row)"
                >设置巡考教师</el-button
              > -->
              <!-- <el-button type="primary" @click="offline(scope.row)"
                >线下预约</el-button
              >
              <el-button type="primary"
                >线上预约</el-button
              >
              <el-button type="primary" @click="adjustment(scope.row)"
                >生成调整</el-button
              > -->
            <!-- </template>
          </el-table-column> -->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 添加巡考教师 -->
      <el-dialog
        title="添加巡考教师"
        :visible.sync="dialogVisibleUpload"
        width="60%"
      >
         <div style="margin-bottom: 20px; text-align: left">
          <el-button
            type="primary"
            icon="el-icon-circle-close"
            @click="dialogVisible2 = false"
            >关闭</el-button
          >
          <el-button type="primary" icon="el-icon-remove-outline"
            >保存并关闭</el-button
          >
        </div>
        <div>
          <el-form :model="search" class="demo-ruleForm" inline>
            <div style="text-align: left; margin-bottom: 20px">
              <el-form-item style="margin-bottom: 0">
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="姓名/工号"
                  style="width: 300px"
                ></el-input>
                <el-button
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div style="margin-bottom: 20px">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="date" label="工号" width="150">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="150">
                <template>
                    <div style="color: #1890ff;cursor: pointer;" @click="adjustment()">
                        1221
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="性别" width="120">
            </el-table-column>
            <el-table-column prop="name" label="身份证号" width="180">
            </el-table-column>
            <el-table-column prop="name" label="职称" width="180">
            </el-table-column>
            <el-table-column prop="name" label="学历" width="120">
            </el-table-column>
            <el-table-column prop="name" label="身份类型" width="160">
            </el-table-column>
            <el-table-column prop="name" label="教师类型" width="160">
            </el-table-column>
            <el-table-column prop="name" label="移动电话" width="160">
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleUpload = false">取 消</el-button>
          <el-button type="primary" @click="handleSave('ruleForm')"
            >保存后关闭</el-button
          >
        </span>
      </el-dialog>
      <!-- 设置巡考教师 -->
      <el-dialog
        title="设置巡考教师"
        :visible.sync="dialogVisible1"
        width="60%"
      >
        <div style="margin-bottom: 20px; text-align: left">
          <el-button
            type="primary"
            icon="el-icon-circle-close"
            @click="dialogVisible2 = false"
            >关闭</el-button
          >
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="dialogVisibleUpload=true"
            >添加</el-button
          >
          <el-button type="primary" icon="el-icon-remove-outline"
            >删除</el-button
          >
        </div>
        <div>
          <el-form :model="search" class="demo-ruleForm" inline>
            <div style="text-align: left; margin-bottom: 20px">
              <el-form-item style="margin-bottom: 0">
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="姓名/工号"
                  style="width: 300px"
                ></el-input>
                <el-button
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div style="margin-bottom: 20px">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="date" label="工号" width="150">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="150">
            </el-table-column>
            <el-table-column prop="name" label="性别" width="120">
            </el-table-column>
            <el-table-column prop="name" label="身份证号" width="180">
            </el-table-column>
            <el-table-column prop="name" label="职称" width="180">
            </el-table-column>
            <el-table-column prop="name" label="学历" width="120">
            </el-table-column>
            <el-table-column prop="name" label="身份类型" width="160">
            </el-table-column>
            <el-table-column prop="name" label="教师类型" width="160">
            </el-table-column>
            <el-table-column prop="name" label="移动电话" width="160">
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-bottom: 20px; text-align: right">
          <el-button @click="dialogVisible1 = false">取消</el-button>
          <el-button type="primary">保存后关闭</el-button>
        </div>
      </el-dialog>
      <!-- 生成调整 -->
      <el-dialog
      title="编辑"
      :visible.sync="dialogVisible3"
      width="40%"
      style="text-align: left"
    >
    <p style="color:red;font-size:14px;margin-bottom:1rem">（提示：教师工号将作为教师登录帐号,身份证号后6位将作为老师的初始密码）</p>
      <el-form
        :model="teacherForm"
        :rules="teacherRules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="120px"
      >
      <el-row>
          <el-col :span="12">
            <el-form-item label="上传照片：" prop="name">
                <label>
                <input type="file" id="file" style="display:none;" />
                <!-- <img class="portrait_image" src="@/assets/defaultCover.png" style="width:30%" alt /> -->
                <div class="portrait_Sprite">
                  <span>上传照片</span>
                </div>
              </label>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工号：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别：" prop="name">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号：" prop="name">
               <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="专业方向：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职称：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="学历：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
            <el-form-item label="在任：" prop="name">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份类型：" prop="name">
             <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="移动电话：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="固定电话：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="电子邮箱：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮政编码：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="微信：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="QQ：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="教师类型：" prop="name">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
             <el-col :span="12">
            <el-form-item label="工作单位：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
          <el-row>
          <el-col :span="12">
            <el-form-item label="联系地址：" prop="name">
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textarea">
                    </el-input>
            </el-form-item>
          </el-col>
         <el-col :span="12">
            <el-form-item label="备注：" prop="name">
              <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textarea">
                    </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      radio: "1",
      title: "",
      dialogVisibleUpload: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      dialogVisible9: false,
      dialogVisible10: false,
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      teacherRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        fullName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确" },
        ],
        email: [
          { required: false, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: "邮箱格式不正确",
          },
        ],
        idCard: [
          { required: false, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "身份证号格式不正确",
          },
        ],
      },
      tableData: [
        {
          id: "1",
          name: "王小虎",
          num: "123",
          sex: "男",
          idCard: "3201",
          phone: "13333333333",
          address: "上海市普陀区金沙江路 1518 弄",
          memo: "备注",
        },
      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {},
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    //批量上传图片
    Batch() {
      this.dialogVisibleUpload = true;
    },
    reshuffle() {
      this.dialogVisible1 = true;
    },
    offline() {
      this.dialogVisible2 = true;
    },
    adjustment() {
      this.dialogVisible3 = true;
    },
    //添加考试范围
    testscope() {
      this.dialogVisible4 = true;
    },
    //查看考试课程
    examine() {
      this.dialogVisible6 = true;
    },
    //设置场次
    sessions() {
      this.dialogVisible7 = true;
    },
    etc() {
      this.dialogVisible9 = true;
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
</style>