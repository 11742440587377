<template>
    <div class="crlmRlnce">
        <div
                class="rigth"
                :class="fullscreen ? 'YesScreen' : 'NoScreen'"
                id="rigth">
            <!-- 可删除标签 -->
            <div class="delBq">
                <el-tag
                        v-for="tag in HbrtTag"
                        :key="tag.name"
                        closable
                        :type="tag.type">
                    {{ tag.name }}
                </el-tag>
            </div>
            <!-- 按钮 -->
            <div class="btn">
                <el-button icon="el-icon-bottom" type="primary" @click="download">导出招生在校生来源情况</el-button>
                <el-button icon="el-icon-bottom" type="primary" @click="downloads">导出成人专科分专业学生分</el-button>
                <el-button icon="el-icon-bottom" type="primary" @click="downloadss">导出成人本科分专业学生数</el-button>
            </div>
            <!-- 对话框 -->
            <div class="dialogBox">
                <el-dialog
                        :center='true'
                        :title="dialogTitle"
                        :visible.sync="dialogVisible"
                        width="50%">
                    <h5>导出进度</h5>
                    <el-progress :text-inside="true" :stroke-width="30" :percentage="percentage"></el-progress>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="yesDialog">
                            确 定
                        </el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
    // //import "bootstrap/dist/css/bootstrap.min.css";
    // //import "bootstrap/dist/js/bootstrap.min.js";

    export default {
        data() {
            return {
                HbrtTag: [
                    {
                        name: "高基报表",
                        type: "",
                    },
                ],
                //   对话框
                dialogVisible: false,
                //   储存的标题
                dialogTitle: '',
                // 进度条
                percentage: 0,
                // quanping
                fullscreen: false,
            };
        },
        methods: {
            // 导出
            download() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                let siteId = localStorage.getItem("siteId");
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduGaoJi/exportStudentSourceStat",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        "siteId": siteId,
                    },
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let url = window.URL.createObjectURL(blob);
                    window.location.href = url;
                }).catch((err) => {
                });
            },
            // 导出
            downloads() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                let siteId = localStorage.getItem("siteId");
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduGaoJi/exportAdultSpecialtyStudent",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        "siteId": siteId,
                    },
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let url = window.URL.createObjectURL(blob);
                    window.location.href = url;
                }).catch((err) => {
                });
            },
            // 导出
            downloadss() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                let siteId = localStorage.getItem("siteId");
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduGaoJi/exportAdultUndergraduateStudent",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        "siteId": siteId,
                    },
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let url = window.URL.createObjectURL(blob);
                    window.location.href = url;
                }).catch((err) => {
                });
            },

            // 弹窗确定
            yesDialog() {
                this.dialogVisible = false
                this.percentage = 0
            }
        },
    };
</script>
<style scoped>
    html {
        font-size: 16px !important;
    }

    h5 {
        width: 100%;
        color: #000;
        text-align: left;
    }

    /* 侧边栏 */
    .left {
        width: 7%;
        display: inline-block;
        height: calc(100vh - 3.3rem);
    }

    /* 右侧主体 */
    .rigth {
        width: 93%;
        font-size: 1rem;
        display: inline-block;
        /* background-color: #fff; */
        background: rgba(164, 174, 224, 0.11);
        float: right;
        padding: 1rem;
    }

    /* 可删除标签 */
    .delBq {
        font-size: 1rem;
        bottom: 0.1rem solid #ddd;
        text-align: left;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ddd;
        text-align: left;
    }

    /* 按钮 */
    .btn {
        width: 100%;
        font-size: 1rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0.75rem 0;
    }

    @media (max-width: 580px) {
        /* 按钮 */
        .btn {
            width: 100%;
            font-size: 1rem;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            padding: 0.75rem 0;
        }

        .btn .el-button {
            width: 13rem;
            margin: .2rem 0;
        }

        /* 弹窗 */
        .dialogBox >>> .el-dialog {
            width: 100% !important;
        }
    }

    /* quanping */
    .YesScreen {
        width: 100vw !important;
        height: 100vh !important;
        background: #fff !important;
    }

    .NoScreen {
    }
</style>
