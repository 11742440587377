<template>
  <div class="big">
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <div class="main">
      <Left></Left>
      <router-view></router-view>
      <div class="mainright">
        <div style="border-color: #ddd;">
          <div class="clearfix">
            <h3 class="ampanel">
              <i class="el-icon-s-fold"></i>
              添加留言
              <div class="amtext">
                <button @click="$router.back(-1)">返回</button>
              </div>
            </h3>
          </div>
          <div style="padding:0.2rem;">
            内容：
            <el-input v-model="input" placeholder="请输入内容"></el-input>
            <el-button type="success">完成</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
        <Footer></Footer>
        <router-view></router-view>
    </div>-->
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.mainright {
  width: 77.8%;
  border: 1px solid transparent;
  border-color: #ddd;
  float: right;
  margin-left: 0.4rem;
}
.clearfix {
  color: #444;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 0.15rem;
}
.ampanel {
  font-size: 0.15rem;
  text-align: left;
}

.amform {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.12rem;
  text-align: left;
}
.el-button--success {
  background-color: #77b723;
  border-color: #77b723;
}
.el-button--text {
  color: #77b723;
}
.el-pagination {
  white-space: normal;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
.bankuai {
  display: inline-block;
  width: 30%;
  margin-bottom: 0.1rem;
}

.zhuangtai {
  display: inline-block;
  margin-right: 0.1rem;
}
.leixing {
  display: inline-block;
}
.fabu {
  display: inline-block;
}
.zhi {
  display: inline-block;
}
@media only screen and (max-width: 540px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-left {
    width: 100% !important;
    margin-bottom: 0.2rem !important;
  }
  .mainright {
    width: 100% !important;
  }
  .el-input {
    width: 50% !important;
  }
  .el-menu-item {
    font-size: 0.3rem !important;
  }
  .header {
    font-size: 0.3rem !important;
    padding: 0.4rem;
  }
  .dp[data-v-1aa8c6e6] {
    display: inline-block;
    font-size: 0.3rem;
    float: left;
    margin-top: 0.09rem;
  }
  .sou[data-v-1aa8c6e6] {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong[data-v-1aa8c6e6] {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
  .bankuai {
    width: 100% !important;
  }
  .zhuangtai {
    margin-bottom: 0.1rem !important;
  }
  .leixing {
    margin-bottom: 0.1rem !important;
  }
  .fabu {
    margin-bottom: 0.1rem !important;
  }
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}

.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 70%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
.amtext {
  display: inline-block;
  float: right;
}
.amtext button {
  padding-left: 0.1rem;
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
  padding-right: 0.1rem;
  background-color: #77b723;
  border: #77b723;
  color: #fff;
  border-radius: 0.05rem;
  margin-left: 0.1rem;
}
</style>
<script>
import Header from "@/components/Backstage";
import Left from "@/components/Backlf";
// import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Left
    // Footer
  },
  data() {
    return {
      input: ""
    };
  },
  methods: {}
};
</script>


