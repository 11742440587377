<template>
  <div>
    <div class="actionBox">
      <!-- 标题 -->
      <div class="action_title">
        <div class="action_title_name">异议申诉进度查询</div>
      </div>
      <!-- 内容 -->
      <div class="action_content">
        <div class="action_content_inp">
          <input type="text" placeholder="请输入查询码或者经办人身份证号" v-model="inputValue" />
          <button @click="putBtn">搜 索</button>
        </div>
        <div class="action_content_item">
          <div class="action_content_item_title">异议申诉信息</div>
          <div class="action_content_item_table">
            <div class="action_content_item_table_div">
              <table>
                <tbody>
                  <tr>
                    <th
                      v-for="(item, index) in thList"
                      :key="index"
                    >
                      {{ item }}
                    </th>
                  </tr>
                  <tr v-for="itemTr in tdList" :key="itemTr.id">
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      thList: [
        "序号",
        "申诉主体",
        "申诉联系人",
        "申诉日期",
        "申诉内容",
        "办理状态",
        "处理结果",
        "处理意见",
      ],
      // 获取的后端数据
      tdList: [],
      // 搜索框
      inputValue:''
    };
  },
  created(){
    this.getList()
  },
  methods: {
    // 请求后端数据
    getList(){
      // this.$Axios.get('')
      // 将this.$Axios.get('')返回值赋值给tdList    this.tdList=返回值
    },
    // 点击搜索
    putBtn(){
      // 获取一下input值再调用获取后端数据
      this.getList()
    }
  },
};
</script>
<style scoped>
html,
body {
  font-size: calc(100vw / 7.5) !important;
}
/* 标题 */
.action_title {
  border: 1px solid #eee;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.action_title_name {
  font-size: 0.7rem;
  padding: 0.4rem 1.3rem;
  background: #ff002d;
  color: #fff;
}
/* 搜索框 */
.action_content_inp {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.action_content_inp input {
  width: 50vw;
  margin: 0 1rem 0 0;
  border: 1px solid #c81522;
  outline: none;
  line-height: 1.5rem;
  font-size: 0.5rem;
  padding:0 1rem;
}

.action_content_inp button {
  background: #c81522;
  outline: none;
  border: none;
  line-height: 1.7rem;
  padding: 0 1.2rem;
  border-radius: 0.2rem;
  font-size: 0.5rem;
  color: #fff;
}
/* table */
.action_content_item_title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.5rem;
  padding: 0 1.5rem;
  font-weight: 600;
}
.action_content_item_table_div{
  
  padding: 0 1.5rem;
}
th{
  font-size: 0.2rem;
}
</style>
