<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        毕业生成绩查询
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-download" type="primary">导出成绩大表</el-button>
            <el-button  type="primary"
              >查询</el-button
            >
            <el-button 
              >重置</el-button
            >
          </el-form-item>
          <div>
            <div style="width: 25%; display: inline-block">
              <el-form-item>
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="姓名/学号"
                  style="width: 250px"
                ></el-input>
                <el-button
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-form-item>
            </div>
            <div style="width:20%;display:inline-block">
           <el-form-item style="margin-bottom:0">
            课程
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
          <div style="width:20%;display:inline-block">
           <el-form-item style="margin-bottom:0">
            函授站
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
           <div style="width:20%;display:inline-block">
           <el-form-item style="margin-bottom:0">
            年级
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
          <div style="width:20%;display:inline-block">
           <el-form-item style="margin-bottom:0">
            层次
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
          <div style="width:20%;display:inline-block">
           <el-form-item style="margin-bottom:0">
            专业
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
          <div style="width:20%;display:inline-block">
           <el-form-item style="margin-bottom:0">
            学习状态
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
          <div style="width:20%;display:inline-block">
           <el-form-item style="margin-bottom:0">
            学期
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="姓名" prop="id" width="160"> </el-table-column>
          <el-table-column label="学号" prop="id" width="160"> </el-table-column>
          <el-table-column label="函授站" prop="id" width="160"> </el-table-column>
          <el-table-column label="年级" prop="id" width="160"> </el-table-column>
          <el-table-column label="层次" prop="id" width="160"> </el-table-column>
          <el-table-column label="专业" prop="id" width="160"> </el-table-column>
          <el-table-column label="课程名称" prop="id" width="160"> </el-table-column>
          <el-table-column label="课程编号" prop="id" width="160"> </el-table-column>
          <el-table-column label="学习状态" prop="id" width="160"> </el-table-column>
          <el-table-column label="学习进度" prop="id" width="160"> </el-table-column>
          <el-table-column label="离线成绩" prop="id" width="160"> </el-table-column>
          <el-table-column label="在线交互" prop="id" width="160"> </el-table-column>
          <el-table-column label="在线作业" prop="id" width="160"> </el-table-column>
          <el-table-column label="免试成绩" prop="id" width="160"> </el-table-column>
          <el-table-column label="考试成绩" prop="id" width="160"> </el-table-column>
          <el-table-column label="总评成绩" prop="id" width="160"> </el-table-column>
          <el-table-column label="总评成绩来源" prop="id" width="160"> </el-table-column>
          <el-table-column label="已发布" prop="id" width="160"> </el-table-column>
          <el-table-column label="查看成绩单" prop="id" width="160"> </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        title="设置总评成绩生成规则"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >
        <div style="margin-bottom: 20px; text-align: left">
          <el-button @click="dialogVisibleUpload = false"
            >关闭</el-button
          >
           <el-button type="primary"
            >确定</el-button
          >
        </div>
        <div >
        <div style="margin-bottom: 20px; text-align: left">
          离线成绩：<el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
          在线交互：<el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
          考试成绩：<el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
          通过成绩 ≥：<el-input v-model="input" placeholder="请输入内容" style="width:40%"></el-input>
        </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisible: false,
      radio: "1",
      title: "",
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [
        {
          id: "1",
          name: "王小虎",
          num: "123",
          sex: "男",
          idCard: "3201",
          phone: "13333333333",
          address: "上海市普陀区金沙江路 1518 弄",
          memo: "备注",
        },
      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {},
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    addexam() {
      this.dialogVisibleUpload = true;
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>