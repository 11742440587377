<style lang="less">
    .login-container {
        .login-box {
            border-radius: 8px;
            max-width: 400px;
            min-width: 300px;
            width: 100%;
            position: absolute;
            right: 100px;
            top: 25%;

            .el-tabs {
                border-radius: 8px;

                .el-tabs__header,
                .el-tabs__nav-wrap,
                .el-tabs__nav-scroll,
                .tabs__nav {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }

                .el-tabs__item {
                    height: 50px;
                    line-height: 50px;
                    font-size: 16px;
                }

                .login-Form {
                    .el-form-item {
                        .el-form-item__label {
                            height: 40px;
                            line-height: 40px;
                            display: flex;
                            // padding: 0;
                            align-items: center;
                            justify-content: center;

                            img {
                                display: block;
                                width: unset;
                            }
                        }

                        .el-form-item__content {
                            .el-input__inner,
                            .el-select {
                                width: 100%;
                                height: 40px;
                                border-radius: 2px;
                                cursor: pointer;
                            }
                        }
                    }

                    .noMargin {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
</style>
<style scoped lang="less">
    html,
    body {
        padding: 0;
        margin: 0;
        font-size: 16px !important;
    }

    .login-container {
        .xk-container {
            width: 100vw;
            height: 100vh;
            background: url("../../assets/beijing1.png") no-repeat 0 0;
            background-size: cover;
        }

        .logos {
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-items: center;
            padding: 1.3rem 0 1.3rem 3rem;
            background: #0473ca;
            height: 60px;
        }

        .forget {
            width: 100%;
            text-align: center;
            height: 50px;
            line-height: 50px;

            .el-button {
                width: 100px;
                margin: 0 auto;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .submitButtom {
            /* width: 13.5rem; */
            width: 100%;
            height: 40px;
            font-size: 1rem;
            background: rgba(48, 126, 243, 1);
            border: none;
            color: #fff;
            border-radius: 2px;
            cursor: pointer;
            margin: 0 auto;
            display: block;
        }
    }
	
	
	
</style>
<style>
	@media screen and (max-width: 600px) {
	.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
	    margin-bottom: 18px !important;
	    width: 72% !important;
	}
	}
	</style>
<template>
    <div>
        <div class="login-container" v-if="!isMobileData">
            <div class="xk-container">
                <!-- logo -->
                <div class="logos">
                    <img
                            style="width: 190px; height: 70px; position: absolute"
                            src="@/assets/login-tm.png"
                            alt=""
                    />
                </div>
                <!-- 内容 -->
                <div class="login-box" v-if="!systemLogin">
                    <el-tabs type="border-card">
                        <el-tab-pane label="用户登录">
                            <el-form label-width="40px" class="login-Form">
								
                                <el-form-item>
                                    <div slot="label">
                                        <img src="@/assets/school.png" alt=""/>
                                    </div>
                                    <el-select
                                            v-model="roleValue"
                                            placeholder="请搜索院校或者请选择院校"
                                            @change="loginschool($event)"
                                            filterable
                                    >
                                        <el-option
                                                v-for="(item, index) in roleOptions"
                                                :key="index"
                                                :label="item.siteName"
                                                :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <div slot="label">
                                        <img src="@/assets/role.png" alt=""/>
                                    </div>
                                    <el-select
                                            v-model="schoolValue"
                                            placeholder="请选择"
                                            @change="loginNames($event)"
                                    >
                                        <el-option
                                                v-for="item in schoolOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <div slot="label">
                                        <img src="@/assets/accound.png" alt=""/>
                                    </div>
                                    <el-input
                                            suffix-icon="none"
                                            v-model="query.loginAccount"
                                            placeholder="请输入账号"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item class="noMargin">
                                    <div slot="label">
                                        <img src="@/assets/password.png" alt=""/>
                                    </div>
                                    <el-input
                                            suffix-icon="none"
                                            v-model="query.password"
                                            placeholder="请输入密码"
                                            show-password
                                    >
                                    </el-input>
                                </el-form-item>
                                <div class="forget">
                                    
                                    <el-tooltip
                                            style=""
                                            content="院校管理员，学生教师勿操作"
                                            placement="top"
                                    >
                                        <el-button type="text" @click="openResetPassWord"> 找回密码</el-button>
                                    </el-tooltip>
                                </div>
                            </el-form>
                            <div class="index_button">
                                <el-button
                                        class="submitButtom"
                                        type="primary"
                                        :loading="loginload"
                                        @click="logins()"
                                >
                                    登录
                                </el-button>
                            </div>
                        </el-tab-pane>
                        
                    </el-tabs>
                </div>
                <div style="width: 100%;height: 50px;background: #282c2f;position: absolute;bottom: 0;">
                    <div style="text-align: center; height: 40px; line-height: 48px">
                        <div
                                v-for="(l, index) in fontList"
                                style="display: inline-block; margin-left: 5px; color: white;cursor: pointer;"
                                :key="index"
                        >
                            <el-link
                                    style="color: #a8a8a8"
                                    v-if="l.bottomType === 1"
                                    target="_blank"
                                    :underline="false"
                                    :href="l.bottomUrl"
                            >
                                {{ l.bottomName }}
                            </el-link>
                            <el-link
                                    @click="openFootPage(l)"
                                    style="color: #a8a8a8"
                                    :underline="false"
                                    v-if="l.bottomType === 2"
                            >{{ l.bottomName }}
                            </el-link>
                        </div>
                        <el-link
                                style="color: #a8a8a8;margin-left: 5px;cursor: pointer;"
                                :underline="false"
                                @click="openSystemLogin">
                            管理员登录
                        </el-link>
                    </div>
                </div>
            </div>
            <el-dialog
                    title="修改密码"
                    :visible.sync="reSetPassWordDialog"
                    width="40%">
                <div>
                    <el-form ref="ruleForm" :rules="rules" :model="reSetPassWordBO" label-width="80px">
                        <el-form-item label="手机号:" prop="phone">
                            <el-input v-model="reSetPassWordBO.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码" prop="code">
                            <el-input v-model="reSetPassWordBO.code" placeholder="请输入短信验证码">
                                <template slot="append">
                                    <el-button @click="sendCode" :disabled="codeBO.codeLoading">
                                        {{codeBO.codeText}}
                                    </el-button>
                                </template>

                            </el-input>
                        </el-form-item>
                        <el-form-item label="新密码:" prop="password">
                            <el-input show-password v-model="reSetPassWordBO.password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="reSetPassWordDialog = false">取 消</el-button>
                    <el-button type="primary" @click="commitResetPwd">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog
                    :visible.sync="systemLogin"
                    width="30%">
                <el-tabs type="border-card">
                    <el-tab-pane label="管理员登录">
                        <el-form label-width="40px" class="login-Form">
                            
                            <el-form-item>
                                <div slot="label">
                                    <img src="@/assets/accound.png" alt=""/>
                                </div>
                                <el-input
                                        suffix-icon="none"
                                        v-model="query.loginAccount"
                                        placeholder="请输入账号"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item class="noMargin">
                                <div slot="label">
                                    <img src="@/assets/password.png" alt=""/>
                                </div>
                                <el-input
                                        suffix-icon="none"
                                        v-model="query.password"
                                        placeholder="请输入密码"
                                        show-password
                                >
                                </el-input>
                            </el-form-item>
                            
                            <div class="forget">
                                
                                <el-tooltip
                                        style=""
                                        content="院校管理员，学生教师勿操作"
                                        placement="top"
                                >
                                    <el-button type="text" @click="openResetPassWord"> 找回密码</el-button>
                                </el-tooltip>
                            </div>
                        </el-form>
                        <div class="index_button">
                            <el-button
                                    class="submitButtom"
                                    type="primary"
                                    :loading="loginload"
                                    @click="logins(1)"
                            >
                                登录
                            </el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>
        </div>
        <div v-if="isMobileData" class="login-container">
            <div class="xk-container">
                <!-- logo -->
                <div class="logos" style="background:none ;justify-content:center;height: 110px;padding:0px;">
                    <img
                            style="width: 250px;  position: absolute"
                            src="@/assets/logo(1).png"
                            alt=""
                    />
                </div>
                <!-- 内容 -->
                <div v-if="!systemLogin" style="width: 90%;margin: 0 auto;margin-top: 100px">
                    <el-tabs type="border-card">
                        <el-tab-pane label="用户登录">
                            <el-form label-width="70px" class="login-Form">
								<el-form-item>
									<el-radio-group v-model="query.loginType" style="margin-bottom: 0px;min-width: 300px;">
									    <el-radio-button :label="item.value" v-if="item.value!=0"  v-for="item in schoolOptions">{{item.label}}</el-radio-button>
									</el-radio-group>
								</el-form-item>
                                <el-form-item style="width: 100% !important;">
                                    <div slot="label">
                                        <img src="@/assets/school.png" alt=""/>
                                    </div>
                                    <el-select
                                            v-model="roleValue"
                                            placeholder="请搜索院校或者请选择院校"
                                            @change="loginschool($event)"
                                            filterable
                                    >
                                        <el-option
                                                v-for="(item, index) in roleOptions"
                                                :key="index"
                                                :label="item.siteName"
                                                :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="mobilehide">
                                    <div slot="label">
                                        <img src="@/assets/role.png" alt=""/>
                                    </div>
                                    <el-select
                                            v-model="schoolValue"
                                            placeholder="请选择"
                                            @change="loginNames($event)"
                                    >
                                        <el-option
                                                v-for="item in schoolOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="width: 100% !important;">
                                    <div slot="label">
                                        <img src="@/assets/accound.png" alt=""/>
                                    </div>
                                    <el-input
                                            suffix-icon="none"
                                            v-model="query.loginAccount"
                                            placeholder="请输入账号"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item class="noMargin" style="width: 100% !important;">
                                    <div slot="label">
                                        <img src="@/assets/password.png" alt=""/>
                                    </div>
                                    <el-input
                                            suffix-icon="none"
                                            v-model="query.password"
                                            placeholder="请输入密码"
                                            show-password
                                    >
                                    </el-input>
                                </el-form-item>
                                <div class="forget">
                                    <el-button type="text" @click="openResetPassWord"> 找回密码</el-button>
                                </div>
                            </el-form>
                            <div class="index_button">
                                <el-button
                                        class="submitButtom"
                                        type="primary"
                                        :loading="loginload"
                                        @click="logins()"
                                >
                                    登录
                                </el-button>
                            </div>
                        </el-tab-pane>
                        
                    </el-tabs>
                </div>
                <div class="mobilehide" style="width: 100%;background: #282c2f;position: absolute;bottom: 0;padding-top: 15px;padding-bottom: 15px">
                    <div style="text-align: center; ">
                        <div
                                v-for="(l, index) in fontList"
                                style="display: inline-block; margin-left: 5px; color: white"
                                :key="index"
                        >
                            <el-link
                                    style="color: #a8a8a8"
                                    v-if="l.bottomType === 1"
                                    target="_blank"
                                    :underline="false"
                                    :href="l.bottomUrl"
                            >
                                {{ l.bottomName }}
                            </el-link>
                            <el-link
                                    @click="openFootPage(l)"
                                    style="color: #a8a8a8"
                                    :underline="false"
                                    v-if="l.bottomType === 2"
                            >{{ l.bottomName }}
                            </el-link>
                        </div>
                        <el-link
                                style="color: #a8a8a8;margin-left: 5px"
                                :underline="false"
                                @click="openSystemLogin">
                            管理员登录
                        </el-link>
                    </div>
                </div>
            </div>
            <el-dialog
                    title="修改密码"
                    :visible.sync="reSetPassWordDialog"
                    width="90%">
                <div>
                    <el-form ref="ruleForm" :rules="rules" :model="reSetPassWordBO" label-width="80px">
                        <el-form-item label="手机号:" prop="phone">
                            <el-input v-model="reSetPassWordBO.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码" prop="code">
                            <el-input v-model="reSetPassWordBO.code" placeholder="请输入短信验证码">
                                <template slot="append">
                                    <el-button @click="sendCode" :disabled="codeBO.codeLoading">
                                        {{codeBO.codeText}}
                                    </el-button>
                                </template>

                            </el-input>
                        </el-form-item>
                        <el-form-item label="新密码:" prop="password">
                            <el-input show-password v-model="reSetPassWordBO.password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="reSetPassWordDialog = false">取 消</el-button>
                    <el-button type="primary" @click="commitResetPwd">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog
                    :visible.sync="systemLogin"
                    width="90%">
                <el-tabs type="border-card">
                    <el-tab-pane label="管理员登录">
                        <el-form label-width="40px" class="login-Form">
                            
                            <el-form-item>
                                <div slot="label">
                                    <img src="@/assets/accound.png" alt=""/>
                                </div>
                                <el-input
                                        suffix-icon="none"
                                        v-model="query.loginAccount"
                                        placeholder="请输入账号"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item class="noMargin">
                                <div slot="label">
                                    <img src="@/assets/password.png" alt=""/>
                                </div>
                                <el-input
                                        suffix-icon="none"
                                        v-model="query.password"
                                        placeholder="请输入密码"
                                        show-password
                                >
                                </el-input>
                            </el-form-item>
                            
                            <div class="forget">
                                
                                <el-button type="text" @click="openResetPassWord"> 找回密码</el-button>
                            </div>
                        </el-form>
                        <div class="index_button">
                            <el-button
                                    class="submitButtom"
                                    type="primary"
                                    :loading="loginload"
                                    @click="logins(1)"
                            >
                                登录
                            </el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>
        </div>
    </div>
</template>
<script>
    import {COURSE_TYPE} from "@/tools/const";
    import axios from "axios"
    export default {
        data() {
            return {
                // 用户信息下拉框
                schoolOptions: [
                    {
                        value: 0,
                        label: "函授站",
                    },
                    {
                        value: 1,
                        label: "教师",
                    },
                    {
                        value: 2,
                        label: "学生",
                    },
                    {
                        value: 4,
                        label: "院校",
                    },
                ],
                schoolValue: "",
                // 授权站下拉框
                roleOptions: [],
                roleValue: "",
                // 获取信息input
                query: {
                    siteId: "",
                    loginType: "",
                    loginAccount: "",
                    password: "",
                },
                loginload: false,
                fontList: [],
                reSetPassWordDialog: false,
                reSetPassWordBO: {
                    phone: '',
                    password: '',
                    code: ''
                },
                codeBO: {
                    countdown: 60,//重置验证码秒数
                    clear: '',//倒计时控制器
                    codeText: '发送',
                    codeLoading: false
                },
                rules: {
                    phone: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                        {min: 11, max: 11, message: '长度为 11 个字符', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入新密码', trigger: 'blur'},
                        {min: 6, max: 8, message: '长度为 6~8 个字符', trigger: 'blur'}
                    ],
                    code: [
                        {required: true, message: '请输入验证码', trigger: 'blur'}
                    ]
                },
                systemLogin: false,
                isMobileData: false
            };
        },
        created() {
            this.userSelect();
            this.queryFront();
            this.isMobile();
        },
        methods: {
            isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                if (flag) {
                    this.isMobileData = true
                } else {
                    this.isMobileData = false
                }
            },
            openFootPage(item) {
                if (item.bottomUrl==null || item.bottomUrl == ''){
                    return;
                }
                localStorage.setItem("footMessage", JSON.stringify(item));
                let routeData = this.$router.resolve({
                    path: '/openFoot'
                });
                window.open(routeData.href, '_blank');
            },
            openSystemLogin() {
                this.systemLogin = true;
            },
            commitResetPwd() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        let data = new FormData();
                        data.append('phone', this.reSetPassWordBO.phone);
                        data.append('password', this.reSetPassWordBO.password);
                        data.append('code', this.reSetPassWordBO.code);
                        this.openLoadingView();
                        this.$axios_supermall.post('/hnjxjy-core/eduSite/retrievePassword', data).then(res => {
                            if (res.data.code === 2000) {
                                this.$message.success("处理成功");
                                this.reSetPassWordDialog = false;
                            }
                        })
                    } else {
                        return false;
                    }
                });

            },
            sendCode() {
                if (this.reSetPassWordBO.phone == null || this.reSetPassWordBO.phone === '') {
                    this.$message.error("请输入手机号");
                    return;
                }
                this.codeBO.codeLoading = true;
                this.codeBO.codeText = '正在发送';
                this.$axios_supermall.get('/hnjxjy-hklm/userInfo/PasswordCode?phone=' + this.reSetPassWordBO.phone).then(res => {
                    if (res.data.code == 2000) {
                        this.$message.success('验证码已发送');
                        this.tempcode();
                    } else {
                        this.codeBO.codeLoading = false;
                        this.codeBO.codeText = '获取';
                        // this.$message.error(res.message);
                    }
                }).catch((err) => {

                });
            },
            tempcode() {
                const TIME_COUNT = 60;
                let capt_str = '';
                if (!this.codeBO.clear) {
                    this.codeBO.countdown = TIME_COUNT;
                    this.codeBO.clear = setInterval(() => {
                        if (this.codeBO.countdown > 0 && this.codeBO.countdown <= TIME_COUNT) {
                            this.codeBO.countdown--;
                            capt_str = this.codeBO.countdown + "秒";
                            // this.$set(this,'yzmtext',capt_str);
                            this.codeBO.codeText = capt_str
                        } else {
                            capt_str = "重发";
                            this.codeBO.codeLoading = false;
                            this.codeBO.codeText = capt_str;
                            clearInterval(this.codeBO.clear);
                            this.codeBO.clear = null;
                        }
                    }, 1000)
                }
            },
            openResetPassWord() {
                this.reSetPassWordBO = {
                    phone: '',
                    password: '',
                    code: ''
                };
                this.reSetPassWordDialog = true;
            },
            queryFront() {
                this.$axios_supermall
                    .get("/hnjxjy-journalism/cmsBottom/queryFront")
                    .then((res) => {
                        if (res.data.code === 2000) {
                            this.fontList = res.data.data;
                        }
                    });
            },
            // 获取学校下拉列表
            loginschool(e) {
                this.query.siteId = e;
            },
            // 用户信息下拉框
            async userSelect() {
                this.$axios_supermall
                    .get("hnjxjy-core/eduSite/queryAllSiteByAdmin")
                    .then((res) => {
                        this.roleOptions = res.data.data;
                    });
            },
            // 获取用户下拉列表
            loginNames(e) {
                this.query.loginType = e;
            },
            // 点击登录
            async logins(loginType) {
                if (loginType !== 1) {
                    if (!this.query.siteId) {
                        return this.$message.error("请选择学校信息");
                    }
                }
                let url = "/hnjxjy-security/user/login";
                if (loginType === 1) {
                    this.query.loginType = 3;
                    url = "/hnjxjy-security/user/loginAdmin";
                }
                if (this.query.loginType === "") {
                    return this.$message.error("请选择用户信息");
                }
                if (!this.query.loginAccount) {
                    return this.$message.error("请输入账户");
                }
                if (!this.query.password) {
                    return this.$message.error("请输入密码");
                }
                this.loginload = true;
               
                this.$axios.post(url, this.query)
                    .then((res) => {
                        if (res.data.code !== 2000) {
                            this.loginload = false;
                            // return this.$message.error(res.data.message);
                        }
                        // 储存token
                        let obj = {
                            "x-token": res.data.data.authToken,
                            "x-perm": "perm_query",
                        };
                        this.studentPoint(res.data.data);
                        let objStr = JSON.stringify(obj);
                        localStorage.setItem("siteId", res.data.data.userInfo.id);
                        localStorage.setItem("accountType", res.data.data.accountType);
                        localStorage.setItem(
                            "userInfo",
                            JSON.stringify(res.data.data.userInfo)
                        );
                        localStorage.setItem("loginStrage", [objStr]);
                        let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                        //获取权限
                        this.$axios("hnjxjy-security/perm/getUserPermResources", {
                            method: "POST",
                            headers: objPar,
                            params: objPar,
                        }).then((res) => {
                            this.loginload = false;
                            if (res.data.code !== 2000) {
                                return;
                            }
                            // 管理端
                            if (loginType === 1 ||
                                this.query.loginType === 0 ||
                                this.query.loginType === 4) {
                                this.queryRouter(() => {
                                    let firstMenu = '/basic';
                                    if (loginType === 1) {
                                        firstMenu = JSON.parse(localStorage.getItem('menuList'))[0].sysMenuVos[0].sysMenuVos[0].menuUrl;
                                    }
                                    this.$router.push({
                                        // name: "/basic",
                                        name: firstMenu,
                                        params: {
                                            loginHearders: JSON.parse(
                                                localStorage.getItem("loginStrage")
                                            ),
                                            perm: res.data.data,
                                        },
                                    });
                                });
                            } else if (this.query.loginType === 1) {
                                // 菜单切换
                                this.$store.dispatch("set_teacher_menu_switch", COURSE_TYPE.T3);
                                // 教师端
                                this.$router.push("/teacher");
                            } else if (this.query.loginType === 2) {
                                // 菜单切换
                                this.$store.dispatch("set_stu_menu_switch", COURSE_TYPE.C3);
                                // 学生端
                                this.$router.push("/student");
                            }

                            this.$message.success("登录成功");
                            // setTimeout(()=>{
                            //   window.location.reload();
                            // },1000)
                        })
                            .catch(() => {
                                this.loginload = false;
                            });
                    })
                    .catch(() => {
                        this.loginload = false;
                    });
            },

            // 学生端/教师端
            studentPoint(data) {
                if (data) {
                    // 鉴权
                    let obj = {
                        "x-token": data.authToken,
                        "x-perm": "perm_query",
                    };
                    this.$store.dispatch("set_auth", obj);
                    // 存储学生/教师信息
                    this.$store.dispatch("set_user", {
                        ...data.userInfo,
                        lastLoginTime: data.lastLoginTime,
                    });
                }
            },
            queryRouter(callback) {
                this.openLoadingView();
                this.$axios_supermall
                    .get("/hnjxjy-admin/sysPermission/queryAllSysPermissionByUserId")
                    .then((response) => {
                        if (response.data.code === 2000) {
                            response.data.data.forEach((e) => {
                                if (e.menuType === 0) {
                                    let list = e.sysMenuVos;
                                    this.repliceNull(list);
                                    localStorage.setItem("menuList", JSON.stringify(list));
                                    let permissions = [];
                                    this.allPermissions(list, permissions);
                                    localStorage.setItem(
                                        "permissionLink",
                                        JSON.stringify(permissions)
                                    );
                                    if (callback) {
                                        callback();
                                    }
                                }
                            });
                        }
                    });
            },
            repliceNull(list) {
                list.forEach((l) => {
                    if (l.sysMenuVos != null && l.sysMenuVos.length > 0) {
                        this.repliceNull(l.sysMenuVos);
                    } else {
                        this.$set(l, "sysMenuVos", null);
                    }
                });
            },
            allPermissions(list, permissions) {
                list.forEach((l) => {
                    if (l.sysMenuVos != null && l.sysMenuVos.length > 0) {
                        this.allPermissions(l.sysMenuVos, permissions);
                    } else {
                        if (l.sysPermissions != null && l.sysPermissions.length > 0) {
                            l.sysPermissions.forEach((p) => {
                                permissions.push(p);
                            });
                        }
                    }
                });
            },
        },
    };
</script>