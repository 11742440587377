import { render, staticRenderFns } from "./onlineScore.vue?vue&type=template&id=53e93252&scoped=true"
import script from "./onlineScore.vue?vue&type=script&lang=js"
export * from "./onlineScore.vue?vue&type=script&lang=js"
import style0 from "./onlineScore.vue?vue&type=style&index=0&id=53e93252&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e93252",
  null
  
)

export default component.exports