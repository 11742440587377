<template>
  <div>

    <div class="header">

      <div>
        <div style="padding: 1rem; text-align: left">在线考试查询</div>
        <div class="search">
          <el-form :model="search" class="demo-ruleForm" inline>
            <div class="search_content">
              <el-form-item>
                <el-button icon="el-icon-download" type="primary"
                  >导出</el-button
                >
              </el-form-item>
            </div>
            <div class="arrow">
              <div class="input">
                <el-input
                  placeholder="课程名称/课程编号"
                  v-model="input3"
                  class="input-with-select"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    class="append"
                  ></el-button>
                </el-input>
              </div>
              <div style="width: 25%; display: inline-block" class="demo">
                <el-col>
                  <span class="demonstration">学期：</span>
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link" style="padding-left: 15px">
                      全部<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="item in options"
                        :key="item.value"
                        >{{ item.label }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </div>
              <div class="container">
                <div class="block">
                  <span class="demonstration">开始日期：</span>
                  <el-date-picker
                    v-model="value1"
                    type="week"
                    format="yyyy 第 WW 周"
                    placeholder="选择周"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="container">
                <div class="block">
                  <span class="demonstration">结束日期：</span>
                  <el-date-picker
                    v-model="value2"
                    type="week"
                    format="yyyy 第 WW 周"
                    placeholder="选择周"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="table-wrap">
          <el-table
            class="multipleTable"
            ref="multipleTable"
            border
            height="550"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="name" sortable label="学生学号" width="150">
            </el-table-column>
            <el-table-column
              prop="address"
              label="学生姓名"
              sortable
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="boolen"
              label="学期"
              sortable
              width="80"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="课程名称"
              sortable
              width="120"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="课程编号"
              sortable
              width="150"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="考试名称"
              sortable
              width="150"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="作答时间"
              sortable
              width="150"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="用时"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="提交方式"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="浏览器"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="浏览器版本"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="操作系统"
              width="150"
            ></el-table-column>
          </el-table>
          <!-- </el-table> -->
          <div style="padding: 1rem" class="bottom">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="5"
              layout="total, sizes, prev, pager, next, jumper"
              :total="20"
            >
            </el-pagination>
          </div>
        </div>

        <!-- 编辑 -->
        <el-dialog
          title="2020毕业设计批次编辑"
          :visible.sync="dialogVisibleUpload1"
          width="40%"
        >
          <div style="margin-bottom: 20px; text-align: left">
            <el-button @click="dialogVisibleUpload1 = false">关闭</el-button>
            <el-button type="primary">保存后关闭</el-button>
          </div>
          <div>
            <div style="margin-bottom: 20px; text-align: left">
              批次代码：<el-input
                v-model="input"
                placeholder="请输入内容"
                style="width: 40%"
              ></el-input>
            </div>
            <div style="margin-bottom: 20px; text-align: left">
              批次名称：<el-input
                v-model="input"
                placeholder="请输入内容"
                style="width: 40%"
              ></el-input>
            </div>
            <div style="margin-bottom: 20px; text-align: left">
              毕业设计日期：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              至：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
            <div style="margin-bottom: 20px; text-align: left">
              开题日期：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              至：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
            <div style="margin-bottom: 20px; text-align: left">
              撰写论文日期：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              至：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
            <div style="margin-bottom: 20px; text-align: left">
              免试申请时间：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              至：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
            <div style="margin-bottom: 20px; text-align: left">
              成绩上报日期：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              至：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
            <div style="margin-bottom: 20px; text-align: left">
              学生评价时间：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              至：
              <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      total: 0,
      pageNum: 1,
      input3: "",
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload1: false,
      dialogVisible: false,
      radio: "1",
      title: "",
      value1: "",
      value2: "",
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableData: [
        {
          id: 1,
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 2,
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 3,
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 4,
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 5,
          date: "2016-05-08",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 6,
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 7,
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 7,
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 7,
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 7,
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 7,
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 7,
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 7,
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 7,
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      multipleSelection: [],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    // handleSelectionChange(val) {
    //   // 单选
    //   if (this.multipleSelection.length > 1) {
    //     this.multipleSelection = [];
    //   }
    //   if (val.length > 1) {
    //     this.$refs.multipleTable.clearSelection();
    //     this.$refs.multipleTable.toggleRowSelection(val.pop());
    //   }
    //   this.multipleSelection = val;
    // },
    handleSearch() {},
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    editor() {
      this.dialogVisibleUpload1 = true;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange(e) {
    },
  },
};
</script>
<style scoped>
/* 通用样式 */
html {
  font-size: 16px !important;
}
/* 头部下面侧边栏css样式 */
/* 左侧 */
.header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.header_top {
  width: 7%;
}

.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
/* search按钮布局 */
.search_content {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}
/* 表格样式 */
.arrow {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}
/* 输入框样式 */
.el-input {
  width: 33.2rem;
}
/* 下拉菜单样式 */
.el-dropdown {
  width: 75%;
  margin-left: 10px;
  border: 1px solid #ccc;
  line-height: 36px;
  background-color: #fff;
}
/* 下拉菜单向下的箭头调整 */
.el-icon-arrow-down {
  float: right;
  padding-right: 20px;
  line-height: 36px;
}
.arrow >>> .el-dropdown {
  text-align: left;
}
/* 二级菜单调整 */
.el-popper {
  width: 300px;
}
/* 日期选择器样式 */
.container {
  width: 440px;
}
/* 表单样式 */
.table-wrap {
  padding: 20px 0 0 20px;
}
/* 底部分页样式 */
.bottom {
  background-color: #fff;
  text-align: left;
}
.el-pagination >>> .btn-prev {
  background-color: rgb(244, 244, 245);
  border: 1px solid rgb(244, 244, 245);
  padding-right: 3px;
  border-radius: 3px;
  color: rgb(197, 198, 205);
}
.el-pagination >>> .btn-next {
  background-color: rgb(244, 244, 245);
  border-radius: 3px;
  border: 1px solid rgb(244, 244, 245);
  padding-left: 3px;
  color: rgb(197, 198, 205);
}
/* 分页栏页面数字 */
.bottom >>> .el-pager li {
  margin: 0 5px 0 5px;
  background-color: rgb(244, 244, 245);
}
.bottom >>> .el-pager li.active {
  margin: 0 5px 0 5px;
  background-color: rgb(24, 144, 255);
  color: #fff;
}
.rigth >>> .el-table td,
.rigth >>> .el-table th {
  text-align: center;
}
/* @media screen and (max-width: 550px) { */
  /* .demo,
  .container,
  .input,
  .arrow{
    width: 100%;
  }    */
  /* .arrow  {
    width: 100%;
    font-size: 0.8rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f00;
  } */
  
/* } */
</style>