<template>
  <div class="sptdt">

    <div class="main">

      <div
        class="rigth"
        :class="fullscreen ? 'YesScreen' : 'NoScreen'"
        id="rigth"
      >
        <!-- 可删除标签 -->
        <div class="delBq">
          <el-tag
            v-for="tag in HbrtTag"
            :key="tag.name"
            closable
            :type="tag.type"
          >
            {{ tag.name }}
          </el-tag>
        </div>
        <!-- 按钮 -->
        <div class="btn">
          <el-button
            icon="el-icon-download"
            type="primary"
            size="mini"
            @click="exportBtn()"
            >导出</el-button
          >
        </div>
        <!-- input -->
        <div class="input_date">
          <div>
            <el-input
              placeholder="请输入内容"
              v-model="putInput"
              @clear="getsptdtList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getsptdtList"
              ></el-button>
            </el-input>
          </div>
          <div>
            <p>开始日期到结束日期：</p>
            <el-date-picker
              v-model="timeDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="timeChange"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="tableZ">共选中 {{TableZ}} 条数据</div>
        <!-- 表格 -->
        <div class="table_card">
          <el-table
            :data="tableData"
            tooltip-effect="dark"
            width="93%"
            border
            stripe
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              label="序号"
              width="70"
              type="index"
            ></el-table-column>
            <el-table-column label="课程名称" width="150" prop="className">
            </el-table-column>
            <el-table-column label="课程编号" width="150" prop="classNum">
            </el-table-column>
            <el-table-column label="教师名称" width="150" prop="teacherName">
            </el-table-column>
            <el-table-column label="通知公告数目" width="150" prop="NoticeNum">
            </el-table-column>
            <el-table-column
              label="导学资料发帖数"
              width="150"
              prop="GuidanceNum"
            >
            </el-table-column>
            <el-table-column
              label="在线答疑提问数"
              width="150"
              prop="questions"
            >
            </el-table-column>
            <el-table-column label="在线答疑回复数" width="150" prop="reply">
            </el-table-column>
            <el-table-column
              label="在线答疑未回复数"
              width="150"
              prop="NoReply"
            >
            </el-table-column>
            <el-table-column
              label="在线答疑提问数"
              width="150"
              prop="questionsOne"
            >
            </el-table-column>
            <el-table-column
              label="在线24小时回复率"
              width="150"
              prop="dayResponseRate"
            >
            </el-table-column>
            <el-table-column
              label="在线48小时回复率"
              width="150"
              prop="twoDayResponseRate"
            >
            </el-table-column>
          </el-table>
          <div class="person_Pager">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="5"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      HbrtTag: [
        {
          name: "课程督学助学统计",
          type: "",
        },
      ],
      //   查询input框
      putInput: "",
      // 开始结束日期
      timeDate: "",
      //   表格
      tableData: [
        {
          id: 0,
          className: "课程名称",
          classNum: "课程编号",
          teacherName: "教师名称",
          NoticeNum: "通知公告数目",
          GuidanceNum: "导学资料发帖数",
          questions: "在线答疑提问数",
          reply:'在线答疑回复数',
          NoReply: "在线答疑未回复数",
          questionsOne: "在线答疑提问数",
          dayResponseRate: "在线24小时回复率",
          twoDayResponseRate: "在线48小时回复率",
        },
        {
          id: 1,
          className: "课程名称",
          classNum: "课程编号",
          teacherName: "教师名称",
          NoticeNum: "通知公告数目",
          GuidanceNum: "导学资料发帖数",
          questions: "在线答疑提问数",
          reply:'在线答疑回复数',
          NoReply: "在线答疑未回复数",
          questionsOne: "在线答疑提问数",
          dayResponseRate: "在线24小时回复率",
          twoDayResponseRate: "在线48小时回复率",
        },
        {
          id: 2,
          className: "课程名称",
          classNum: "课程编号",
          teacherName: "教师名称",
          NoticeNum: "通知公告数目",
          GuidanceNum: "导学资料发帖数",
          questions: "在线答疑提问数",
          reply:'在线答疑回复数',
          NoReply: "在线答疑未回复数",
          questionsOne: "在线答疑提问数",
          dayResponseRate: "在线24小时回复率",
          twoDayResponseRate: "在线48小时回复率",
        },
      ],
      TableZ:0,
      //   分页器
      currentPage4: 1,
      total: 7,
      // quanping
      fullscreen: false,
    };
  },
  methods: {
    getsptdtList() {},
    exportBtn() {
    },
    // 时间
    timeChange(newVal){
    },
    // 分页器
    handleSizeChange(newPage) {
    },
    handleCurrentChange(newSize) {
    },
  },
};
</script>
<style scoped >
html {
  font-size: 16px !important;
}

.main {
  display: flex;
}

/* 侧边栏 */
.left {
  width: 7%;
  height: calc(100vh - 3.3rem);
}
/* 右侧主体 */
.rigth {
  width: 93%;
  font-size: 1rem;
  /* background-color: #fff; */
  background: rgba(164, 174, 224, 0.11);
  padding: 1rem;
}
/* 可删除标签 */
.delBq {
  font-size: 1rem;
  bottom: 0.1rem solid #ddd;
  text-align: left;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
}
/* 按钮 */
.btn {
  width: 100%;
  font-size: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0;
}
/* input */
.input_date {
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.input_date > div {
  padding-right: 2rem !important;
}
.input_date > div:nth-child(2) p {
  margin-right: 1rem;
  margin-bottom: 0;
  display: inline-block;
}
.input_date >>> .el-input__inner {
  width: 25rem;
  height: 30px !important;
  line-height: 30px !important;
}
.input_date >>> .el-range__icon,
.input_date >>> span {
  line-height: 23px !important;
}
@media (max-width:550px) {
    .input_date {
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}
.input_date > div:nth-child(2) p {
  margin-right: 1rem;
  margin:.3rem 0;
  display: block;
}
.input_date >>> .el-input__inner {
  width: 10rem;
  height: 30px !important;
  line-height: 30px !important;
}
}
/* 表格 */
.table_card {
  background: #fff;
}
.tableZ{
    width: 100%;
    text-align: left;
      margin: 1rem 0 0 0;
    color: #409EFF;

}
.table_card >>> .el-table th > .cell {
  text-align: center !important;
}
.table_card >>> .el-table .cell {
  text-align: center !important;
}
/* 分页器 */
.person_Pager {
  font-size: 1rem;
  width: 100%;
  padding: 1rem 0;
  background: #fff;
  overflow-x: scroll;
}
.person_Pager::-webkit-scrollbar {
  height: 0;
  width: 0;
}
/* quanping */
.YesScreen {
  width: 100vw !important;
  height: 100vh !important;
  background: #fff !important;
}
.NoScreen {
}
</style>
