<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        考场查看
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary">导出</el-button>
            <el-button icon="el-icon-upload2" type="primary" @click="Batch()"
              >批量导入设置考场时间</el-button
            >
            <!-- <el-button icon="el-icon-setting" type="primary" @click="reshuffle()"
              >设置学籍异动（层次和专业）</el-button
            > -->
            <el-button icon="el-icon-time" type="primary">设置考场时间</el-button>
            <el-button icon="el-icon-time" type="primary">设置允许上传时间</el-button>
            <el-button icon="el-icon-finished" type="primary">异常数据校对</el-button>
            <!-- <el-button icon="el-icon-close" type="primary">驳回</el-button> -->
          </el-form-item>
          <div>
            <el-form-item label="考场号" style="margin-bottom: 0">
              <el-input
                v-model="search.name"
                clearable
                @input="() => (search.name = search.name.trim())"
                placeholder="考场号"
                style="width: 300px"
              ></el-input>
              <el-button
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-form-item>
            <el-form-item style="margin-bottom:0;margin-left:2rem">
            函授站
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
           <el-form-item style="margin-bottom:0;margin-left:2rem">
            考试批次
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-bottom:0;margin-left:2rem">
            场次
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
           <el-button type="primary">查询</el-button>
            <el-button>重置</el-button>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="函授站" prop="name">
            <!-- <template>
              <div style="color: #1890ff; cursor: pointer" @click="testscope()">添加考试范围</div>
            </template> -->
          </el-table-column>
          <el-table-column label="场次编号" prop="name">
            <!-- <template>
              <div style="color: #1890ff; cursor: pointer" @click="examine()">查看考试课程</div>
            </template> -->
          </el-table-column>
          <el-table-column label="场次" prop="sex"> </el-table-column>
          <el-table-column label="考试开始时间" prop="sex"> </el-table-column>
          <el-table-column label="考试结束时间" prop="sex"> </el-table-column>
          <el-table-column label="考场号" prop="sex"> </el-table-column>
          <el-table-column label="考试人数" prop="sex"> </el-table-column>
          <el-table-column label="考场地点" prop="sex"> </el-table-column>
          <el-table-column label="监考教师" prop="sex"> </el-table-column>
          <!-- <template>
              <div style="color: #1890ff; cursor: pointer" @click="sessions()">设置场次</div>
            </template> -->
          <!-- <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" @click="reshuffle(scope.row)"
                >设置巡考教师</el-button
              > -->
              <!-- <el-button type="primary" @click="offline(scope.row)"
                >线下预约</el-button
              >
              <el-button type="primary"
                >线上预约</el-button
              >
              <el-button type="primary" @click="adjustment(scope.row)"
                >生成调整</el-button
              > -->
            <!-- </template>
          </el-table-column> -->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入 -->
       <el-dialog
        title="上传文件"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >
        <div style="margin-bottom: 20px; text-align: left">
          <el-button type="primary">下载模板</el-button>
          <span style="color: red; margin-left: 0.5rem"
            >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件， 且不超过50M
          </div>
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      radio: "1",
      title: "",
      dialogVisibleUpload: false,
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [
        {
          id: "1",
          name: "王小虎",
          num: "123",
          sex: "男",
          idCard: "3201",
          phone: "13333333333",
          address: "上海市普陀区金沙江路 1518 弄",
          memo: "备注",
        },
      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {},
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    //批量上传图片
    Batch() {
      this.dialogVisibleUpload = true;
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
</style>