<template>
  <div class="big">
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <div class="main">
      <Left></Left>
      <router-view></router-view>
      <div class="mainright">
        <div style="border-color: #ddd;">
          <div class="clearfix">
            <h3 class="ampanel">
              <i class="el-icon-s-fold"></i>
              您当前的位置：特色课程
            </h3>
          </div>
          <div class="baimg">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="date" label="课程名称" width="620">
                  <img src="@/assets/111.jpg" alt="">
                  <router-link to="">
                      考前辅导
                  </router-link>
              </el-table-column>
              <el-table-column prop="name" label="教师信息" width="410"></el-table-column>
            </el-table>
            <div class="block" style="padding:0.1rem;">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage1"
                :page-size="100"
                layout="total, prev, pager, next"
                :total="1000"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
        <Footer></Footer>
        <router-view></router-view>
    </div>-->
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.baimg img{
    width: 15%;
}
.baimg a {
    text-decoration: none;
    color: #0e90d2;
    margin-left: 0.1rem;
}
.baimg a:hover{
    text-decoration:underline;
}
.mainright {
  width: 77.8%;
  border: 1px solid transparent;
  border-color: #ddd;
  float: right;
  margin-left: 0.4rem;
}
.clearfix {
  color: #444;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 0.15rem;
}
.ampanel {
  font-size: 0.15rem;
  text-align: left;
}

.amform {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.12rem;
  text-align: left;
}
.el-button--success {
  background-color: #77b723;
  border-color: #77b723;
}
.el-button--text {
  color: #77b723;
}
.el-pagination {
  white-space: normal;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
.bankuai {
  display: inline-block;
  width: 30%;
  margin-bottom: 0.1rem;
}

.zhuangtai {
  display: inline-block;
  margin-right: 0.1rem;
}
.leixing {
  display: inline-block;
}
.fabu {
  display: inline-block;
}
.zhi {
  display: inline-block;
}
@media only screen and (max-width: 1100px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-left {
    width: 100% !important;
    margin-bottom: 0.2rem !important;
  }
  .mainright {
    width: 100% !important;
  }
  .el-input {
    width: 50% !important;
  }
  .el-menu-item {
    font-size: 0.3rem !important;
  }
  .header {
    font-size: 0.3rem !important;
    padding: 0.4rem;
  }
  .dp[data-v-1aa8c6e6] {
    display: inline-block;
    font-size: 0.3rem;
    float: left;
    margin-top: 0.09rem;
  }
  .sou[data-v-1aa8c6e6] {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong[data-v-1aa8c6e6] {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
  .bankuai {
    width: 100% !important;
  }
  .zhuangtai {
    margin-bottom: 0.1rem !important;
  }
  .leixing {
    margin-bottom: 0.1rem !important;
  }
  .fabu {
    margin-bottom: 0.1rem !important;
  }
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}

.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 70%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>
<script>
import Header from "@/components/Backstage";
import Left from "@/components/Backlf";
// import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Left
    // Footer
  },
  data() {
    return {
      value1: "",
      value2: "",
      value: "",
      input: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogFormVisible3: false,
      currentPage1: 4,
      currentPage3: 2,
      region: "",
      region1: "",
      tableData: [
        {
          date: "考前辅导",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        },
      ]
    };
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
    // onSubmit() {
    //   this.dialogVisible = false;
    // },
    // handleClick() {
    //   this.dialogFormVisible3 = true;
    // },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  }
};
</script>


