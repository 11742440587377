<template>
  <div class="main-left">
    <el-col>
      <h5
        class="header"
        style="background-color: #34c8d5;font-size: 0.16rem;color: rgb(255, 255, 255);padding: 0.3rem;"
      >
      <!-- 我的首页 -->
        <router-link to="/Alliance/Notice" style="color:#fff;text-decoration: none;">
          <i class="el-icon-location"></i> 我的首页
        </router-link>
      </h5>
      <el-menu
        :default-active="onRoutes"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose1"
        background-color="#f4feff"
        text-color="#404040"
        active-text-color="#ffd04b"
        router
      >
      <!-- 通知公告 -->
        <el-menu-item index="Announcement">
          <i class="el-icon-chat-line-round"></i>
          <span slot="title">通知公告</span>
        </el-menu-item>
        <!-- 教学计划 -->
        <el-menu-item index="TeachingPlan">
          <i class="el-icon-notebook-1"></i>
          <span slot="title">教学计划</span>
        </el-menu-item>
        <!-- 我要选课 -->
        <el-menu-item index="ChooseCourses">
          <i class="el-icon-thumb"></i>
          <span slot="title">我要选课</span>
        </el-menu-item>
        <!-- 特色课程 -->
        <el-menu-item index="Characteristic">
          <i class="el-icon-notebook-2"></i>
          <span slot="title">特色课程</span>
        </el-menu-item>
        <!-- 我要缴费 -->
        <el-menu-item index="ToPay">
          <i class="el-icon-coin"></i>
          <span slot="title">我要缴费</span>
        </el-menu-item>
        <!-- 我的教材 -->
        <el-menu-item index="MyTextbook">
          <i class="el-icon-reading"></i>
          <span slot="title">我的教材</span>
        </el-menu-item>
        <!-- 官网页面出现问题 -->
        <el-menu-item index>
          <i class="el-icon-data-board"></i>
          <span slot="title">资源购买</span>
        </el-menu-item>
        <!-- 我的课程 -->
        <el-menu-item index="MyCourse">
          <i class="el-icon-data-line"></i>
          <span slot="title">我的课程</span>
        </el-menu-item>
        <!-- 考试信息 -->
        <el-menu-item index="Examination">
          <i class="el-icon-tickets"></i>
          <span slot="title">考试信息</span>
        </el-menu-item>
        <!-- 官网页面出现问题 -->
        <el-menu-item index>
          <i class="el-icon-trophy-1"></i>
          <span slot="title">我的学位</span>
        </el-menu-item>
        <!-- 我的统考 -->
        <el-menu-item index="Statistics">
          <i class="el-icon-edit"></i>
          <span slot="title">我的统考</span>
        </el-menu-item>
        <!-- 我的成绩 -->
        <el-menu-item index="myScore">
          <i class="el-icon-search"></i>
          <span slot="title">我的成绩</span>
        </el-menu-item>
        <!-- 我的邮件 -->
        <el-menu-item index="Mymail">
          <i class="el-icon-message"></i>
          <span slot="title">我的邮件</span>
        </el-menu-item>
        <!-- 学籍异动 -->
        <el-menu-item index="Change">
          <i class="el-icon-postcard"></i>
          <span slot="title">学籍异动</span>
        </el-menu-item>
        <!-- 毕业填报 -->
        <el-menu-item index>
          <i class="el-icon-collection"></i>
          <span slot="title">毕业填报</span>
        </el-menu-item>
        <!-- 行为统计 -->
        <el-menu-item index="Behavior">
          <i class="el-icon-finished"></i>
          <span slot="title">行为统计</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.main-left {
  width: 18%;
  display: inline-block;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
@media only screen and (max-width: 540px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-left {
    width: 100% !important;
    margin-bottom: 0.2rem !important;
  }
  .el-menu-item {
    font-size: 0.18rem !important;
  }
  .header {
    font-size: 0.2rem !important;
    padding: 0.4rem;
  }
  .dp[data-v-1aa8c6e6] {
    display: inline-block;
    font-size: 0.3rem;
    float: left;
    margin-top: 0.09rem;
  }
  .sou[data-v-1aa8c6e6] {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong[data-v-1aa8c6e6] {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}
.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 45%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>
<script>
export default {
  computed: {
    // 左边栏页面跳转
    onRoutes() {
      return this.$route.path.replace("/", " ");
    }
  },
  data() {
    return {
      input: "",
    };
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
  }
};
</script>
