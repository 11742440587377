<template>
  <div class="settlement">


    <div>
      <!-- 可删除标签 -->
      <div class="delBq">
        <el-tag
          v-for="tag in orderTag"
          :key="tag.name"
          closable
          :type="tag.type"
          @close="closeTag()"
        >
          {{ tag.name }}
        </el-tag>
      </div>
      <!-- 按钮 -->
      <div class="setet_btn">
        <div class="row">
          <div class="col-lg-10 col-md-9 col-sm-10 col-xs-12">
            <div class="row">
              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 export_btn">
                <el-button 
                  icon="el-icon-download"
                  type="primary"
                  size="mini"
                  @click="exportD()"
                  >导出</el-button
                >
              </div>
            </div>
          </div>
          <div class="cha col-lg-2 col-md-3 col-sm-2 col-xs-12">
            <div class="row">
              <div class="col-lg-8 col-md-6 col-sm-8 col-xs-12">
                <el-button type="primary" size="mini" @click="putLook()"
                  >查询</el-button
                >
              </div>
              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <el-button size="mini" @click="angin()">重置</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- input -->
      <div class="input_check">
        <el-form>
          <div class="row">
            <!-- input 订单号/姓名/学号-->
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <span>订单号/姓名/学号</span
              ><el-input
                v-model="input"
                placeholder="请输入内容"
                size="mini"
              ></el-input>
            </div>
            <!-- input 开始时间-->
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <span>开始时间</span>
              <el-date-picker
                size="mini"
                v-model="value1"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
            <!-- input 结束时间-->
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <span>结束时间</span>
              <el-date-picker
                size="mini"
                v-model="value1"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
            <!-- input 订单类型-->
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <span>订单类型</span>
              <el-select v-model="value" placeholder="全部" size="mini">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form>
      </div>
      <div class="box-card">
        <!-- 表格 -->
        <div class="setet_table">
          <el-table :data="setetTable" border style="width: 100%">
            <el-table-column type="index" label="序号" width="70">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="210" sortable>
            </el-table-column>
            <el-table-column prop="classNum" label="学号" width="230" sortable>
            </el-table-column>
            <el-table-column prop="orderNum" label="订单号" width="230" sortable>
            </el-table-column>
            <el-table-column prop="orderTime" label="订单时间" width="230" sortable>
            </el-table-column>
            <el-table-column prop="ArrivalTime" label="到账时间" width="230" sortable>
            </el-table-column>
            <el-table-column prop="orderClass" label="订单类型" width="230" sortable>
            </el-table-column>
            <el-table-column prop="orderDetail" label="订单状态" width="230">
            </el-table-column>
            <el-table-column prop="orderMoney" label="订单金额" width="230" sortable>
            </el-table-column>
            <el-table-column prop="proceduresMoney" label="手续费" width="230" sortable>
            </el-table-column>
            <el-table-column prop="delNum" label="差额" width="230" sortable>
            </el-table-column>
            <el-table-column prop="backClass" label="银行卡类型" width="230" sortable>
            </el-table-column>
            <el-table-column prop="yesProceduresMoney" label="正确手续费" width="230" sortable>
            </el-table-column>
            <el-table-column prop="beizhu" label="备注" width="230" sortable>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import studyHead from "@/components/common/head";

// //import "bootstrap/dist/css/bootstrap.min.css";
// //import "bootstrap/dist/js/bootstrap.min.js";
export default {

  data() {
    return {
      // 可删除标签
      orderTag: [
        {
          type: "",
          name: "订单管理",
        },
      ],
      //   input
      input: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      value1: "",
      //   表格
      setetTable: [{
          id:1,
          name:"姓名",
          classNum:'20201210',
          orderNum:'321654654',
          orderTime:"2020-01-04",
          ArrivalTime:'2020-01-05',
          orderClass:'主要',
          orderDetail:'正常',
          orderMoney:'1300',
          proceduresMoney:'10',
          delNum:'111',
          backClass:'中国银行',
          yesProceduresMoney:'1400',
          beizhu:'备注'
      }],
    };
  },
  methods: {
    // Tag关闭
    async closeTag() {
      const closeTagconfirm = await this.$confirm("是否关闭页面?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (closeTagconfirm !== "confirm") {
        return this.$message.info("已取消!");
      }
      this.$router.push("/basic");
    },
    //   点击导出
    exportD() {
    },
    // 点击查询
    putLook() {
    },
    // 点击重置
    angin() {
    },
  },
};
</script>
<style scoped>
html {
  font-size: 16px !important;
}
.settlement {
  height: 100vh;
}
/* 侧边栏 */
.left {
  width: 7%;
  display: inline-block;
}
/* 右侧主体 */
.rigth {
  width: 93%;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
  padding: 1rem;
}
/* 可删除标签 */
.delBq {
  bottom: 0.1rem solid #ddd;
  text-align: left;

  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
}
/* 按钮 */

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.row div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.row div .el-button{
    margin: .1rem 0;
}
.setet_btn {
  margin: 0.5rem 0 0 0;
}
/* input */
.input_check {
  font-weight: 600;
  width: 100%;
}
.input_check .row div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0.2rem 0;
}
.input_check .row div span {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
}

.input_check .row div .el-select,
.input_check .row div .el-input {
  width: 70%;
}
/* 表格 */
.setet_table >>> .el-table th {
  padding: 0.3rem 0 !important;
}
.setet_table >>> .el-table th > .cell {
  text-align: center !important;
}
.setet_table >>> .el-table .cell {
  text-align: center !important;
}
.setet_table >>>.el-icon-arrow-down{
  color: #000!important;
}
.el-input-number--mini{
  width: 100%;
}
</style>