<template>
    <div>
            <div class="headBox">
                <div class="fiseyo" v-for="(t,index) in tableData">
                    <el-popover placement="right" trigger="hover"
                                :disabled="t.sysMenuVos==null||t.sysMenuVos.length<=0">
                        <div slot="reference" v-if="t.sysMenuVos!=null&&t.sysMenuVos.length>0">
                            <img src="@/assets/yz.png" alt=""/>
                            {{t.menuName}}
                        </div>
                        <div slot="reference" v-if="t.sysMenuVos==null||t.sysMenuVos.length<=0">
                            <router-link :to="t.menuUrl" class="as">
                                <img src="@/assets/xz.png" alt=""/>
                                {{t.menuName}}
                            </router-link>
                        </div>
                        <div v-if="t.sysMenuVos!=null&&t.sysMenuVos.length>0" class="infinite-list"
                             style="overflow:auto;max-height: 500px;width: 700px">
                            <div v-for="(t1,index1) in t.sysMenuVos">
                                <div class="menu_box_top">
                                    <div class="menu_title_box"></div>
                                    {{t1.menuName}}
                                </div>
                                <div class="fiseyor" v-for="(t2,index2) in t1.sysMenuVos">
                                    <a :to="t2.menuUrl" :href="t2.menuUrl" class="as">
                                        {{t2.menuName}}
                                        <i class="el-icon-star-off"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </el-popover>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                tableData: []
            };
        },
        methods: {},
        created() {
            let list = localStorage.getItem('menuList');
            this.tableData = JSON.parse(list);
        }
    };
</script>

<style scoped>
    .headBox {
        /* width: 10%; */
        display: inline-block;

        /* background-color: #fff; */
        /* background-color: rgba(164,174,224,.11); */
    }

    .fiseyo {
        padding: 1rem 1rem;
        font-size: 1.0625rem;
        cursor: pointer;
    }

    .fiseyo:hover {
        color: #5689f4;
        /* background-color: rgb(38,52,69) */
    }

    .fiseyor:hover {
        color: #5689f4;
    }

    .fiseyor {
        padding: 0.8rem 1rem;
        display: inline-block;
        font-size: 1.0625rem;
        cursor: pointer;
        width: 190px;
    }

    .fiseyo img {
        width: 20%;
        vertical-align: bottom;
        margin-right: 0.3rem;
    }

    .menu_box_top {
        font-size: 1.4rem;
        border-bottom: 1px solid #dbdbdb;
        padding: 0.3rem;
        font-weight: bold;
    }

    .menu_title_box {
        background: #1890ff;
        width: 4px;
        height: 13px;
        display: inline-block;
        margin-right: 0.4rem;
        vertical-align: middle;
    }

    .as {
        text-decoration: none;
        color: #606266;
    }


    .as:hover {
        color: #5689f4;
    }

    @media only screen and (max-width: 750px) {
        .headBox {
            width: 100%;
        }

        .fiseyo {
            width: 25%;
            float: left;
        }
    }

</style>
