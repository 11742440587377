<template>
  <div class="big">
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <div class="main">
      <Left></Left>
      <router-view></router-view>
      <div class="mainright">
        <div style="border-color: #ddd;">
          <div class="clearfix">
            <h3 class="ampanel">
              <i class="el-icon-s-fold"></i>
              个人信息
            </h3>
          </div>
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="基础信息" name="first">
              <div style="text-align: right;margin-right: 0.2rem;">
                <el-button type="primary">确定修改</el-button>
              </div>
              <div class="stuInfo">
                <div class="tabdiv">
                  <h3>
                    教学点：
                    <p>自由注册学习中心</p>
                  </h3>
                  <h3>
                    专业：
                    <p>自由注册专业</p>
                  </h3>
                  <h3>
                    性别：
                    <p>-</p>
                  </h3>
                  <h3>
                    证件类型：
                    <p>-</p>
                  </h3>
                  <h3>
                    政治面貌：
                    <p>
                      <el-select v-model="value" placeholder="请选择">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </p>
                  </h3>
                  <h3>
                    移动电话：
                    <p>
                      <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </p>
                  </h3>
                  <h3>
                    电子邮箱：
                    <p>
                      <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </p>
                  </h3>
                  <h3>
                    入学前学校名称：
                    <p>-</p>
                  </h3>
                  <h3>
                    入学前毕业证书编号：
                    <p>-</p>
                  </h3>
                </div>
                <div class="tabdiv">
                  <h3>
                    年级：
                    <p>自由注册学习中心</p>
                  </h3>
                  <h3>
                    学号：
                    <p>mulu</p>
                  </h3>
                  <h3>
                    民族：
                    <p>-</p>
                  </h3>
                  <h3>
                    证件号：
                    <p>-</p>
                  </h3>
                  <h3>
                    文化程度：
                    <p>-</p>
                  </h3>
                  <h3>
                    固定电话：
                    <p>
                      <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </p>
                  </h3>
                  <h3>
                    入学时间：
                    <p>2020/20/20</p>
                  </h3>
                  <h3>
                    入学前学校代码：
                    <p>-</p>
                  </h3>
                  <h3>
                    入学前工作单位：
                    <p>
                      <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </p>
                  </h3>
                </div>
                <div class="tabdiv">
                  <h3>
                    层次：
                    <p>自由注册学习中心</p>
                  </h3>
                  <h3>
                    姓名：
                    <p>mulu</p>
                  </h3>
                  <h3>
                    出生日期：
                    <p>-</p>
                  </h3>
                  <h3>
                    学籍状态：
                    <p>-</p>
                  </h3>
                  <h3>
                    通信地址：
                    <p>-</p>
                  </h3>
                  <h3>
                    培养方式：
                    <p>-</p>
                  </h3>
                  <h3>
                    录取时间：
                    <p>2020/20/20</p>
                  </h3>
                  <h3>
                    入学前毕业年份：
                    <p>-</p>
                  </h3>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="照片" name="second">
              <div class="stuInfo">
                <div class="tabdivr">
                  <h3>
                    白底照片：
                    <p>
                      <img src="@/assets/111.jpg" alt />
                    </p>
                  </h3>
                  <h3>
                    专业：
                    <p>
                      <img src="@/assets/111.jpg" alt />
                    </p>
                  </h3>
                </div>
                <div class="tabdivr">
                  <h3>
                    蓝底照片：
                    <p>
                      <img src="@/assets/111.jpg" alt />
                    </p>
                  </h3>
                  <h3>
                    毕业照片：
                    <p>
                      <img src="@/assets/111.jpg" alt />
                    </p>
                  </h3>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="账号安全" name="third">
              <div class="stuInfo">
                <div class="am-form-group">
                  请输入原始密码：
                  <el-input v-model="input" placeholder="请输入内容"></el-input>
                </div>
                <div class="am-form-group">
                  请输入新密码：
                  <el-input v-model="input" placeholder="请输入内容"></el-input>
                </div>
                <div class="am-form-group">
                  再次输入新密码：
                  <el-input v-model="input" placeholder="请输入内容"></el-input>
                </div>
                <div>
                  <el-button type="primary">确认</el-button>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- <div>
        <Footer></Footer>
        <router-view></router-view>
    </div>-->
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.stuInfo {
  padding: 0.15rem;
  text-align: left;
}
.tabdiv {
  width: 33%;
  float: left;
}
.tabdiv h3 {
  font-size: 0.14rem;
  margin-bottom: 0.2rem;
  text-align: left;
}
.tabdiv p {
  width: 44%;
  display: inline-block;
}

.tabdivr {
  width: 50%;
  display: inline-block;
}
.tabdivr h3 {
  font-size: 0.15rem;
  margin-bottom: 0.3rem;
}
.tabdivr p {
  width: 40%;
  display: inline-block;
}
.tabdivr p img {
  width: 100%;
}
.am-form-group {
  width: 50%;
  font-size: 0.14rem;
  margin-bottom: 0.2rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.mainright {
  width: 77.8%;
  border: 1px solid transparent;
  border-color: #ddd;
  float: right;
  margin-left: 0.4rem;
}
.clearfix {
  color: #444;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 0.15rem;
}
.ampanel {
  font-size: 0.15rem;
  text-align: left;
}

.amform {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.12rem;
  text-align: left;
}
.el-button--success {
  background-color: #77b723;
  border-color: #77b723;
}
.el-button--text {
  color: #77b723;
}
.el-pagination {
  white-space: normal;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
.bankuai {
  display: inline-block;
  width: 30%;
  margin-bottom: 0.1rem;
}

.zhuangtai {
  display: inline-block;
  margin-right: 0.1rem;
}
.leixing {
  display: inline-block;
}
.fabu {
  display: inline-block;
}
.zhi {
  display: inline-block;
}
@media only screen and (max-width: 1100px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-left {
    width: 100% !important;
    margin-bottom: 0.2rem !important;
  }
  .tabdiv {
    width: 100% !important;
  }
  .tabdivr {
    width: 100% !important;
  }
  .mainright {
    width: 100% !important;
  }
  .el-menu-item {
    font-size: 0.3rem !important;
  }
  .am-form-group {
    width: 100% !important;
  }
  .header {
    font-size: 0.3rem !important;
    padding: 0.4rem;
  }
  .dp[data-v-1aa8c6e6] {
    display: inline-block;
    font-size: 0.3rem;
    float: left;
    margin-top: 0.09rem;
  }
  .sou[data-v-1aa8c6e6] {
    width: 100%;
  }
  .neirong[data-v-1aa8c6e6] {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
  .bankuai {
    width: 100% !important;
  }
  .zhuangtai {
    margin-bottom: 0.1rem !important;
  }
  .leixing {
    margin-bottom: 0.1rem !important;
  }
  .fabu {
    margin-bottom: 0.1rem !important;
  }
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}

.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>
<script>
import Header from "@/components/Backstage";
import Left from "@/components/Backlf";
// import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Left
    // Footer
  },
  data() {
    return {
      activeName: "first",
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        }
      ],
      value1: "",
      value2: "",
      value: "",
      input: "",
      currentPage1: 4,
      currentPage3: 2,
      region: "",
      region1: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        }
      ]
    };
  },
  methods: {
    handleClick(tab, event) {
    },
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  }
};
</script>


