<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        在线成绩管理
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <!-- <el-button icon="el-icon-upload2" type="primary" @click="edition()"
              >导入</el-button
            > -->
            <el-button icon="el-icon-edit" type="primary" @click="edition()"
              >添加</el-button
            >
            <el-button icon="el-icon-download" type="primary">导出</el-button>
             <el-button icon="el-icon-search" type="primary" style="margin-bottom:0">搜索</el-button>
               <el-checkbox v-model="checked" style="margin-left:1rem">创建时间</el-checkbox>
            <!-- <el-button type="primary">查询</el-button>
            <el-button>重置</el-button> -->
          </el-form-item>
          <div>
            <div style="width: 20%; display: inline-block">
              <el-form-item style="margin-bottom: 0">
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="函授站名称"
                  style="width: 250px"
                ></el-input>
                <el-button
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-form-item>
            </div>
            <div style="width: 15%; display: inline-block">
              <el-form-item style="margin-bottom: 0">
                <el-select v-model="value" placeholder="状态">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="width: 20%; display: inline-block">
              <el-form-item style="margin-bottom: 0">
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              
            </div>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="ID" width="120" prop="id"></el-table-column>
          <el-table-column label="代码" prop="id" width="150">
          </el-table-column>
          <el-table-column label="函授站名称" prop="id" width="220">
          </el-table-column>
          <el-table-column label="学校" prop="id" width="180">
          </el-table-column>
          <el-table-column label="市级" prop="id" width="180">
          </el-table-column>
          <el-table-column label="负责人" prop="id" width="200">
          </el-table-column>
          <el-table-column label="联系人" prop="id" width="180">
          </el-table-column>
          <el-table-column label="邮箱" prop="id" width="180">
          </el-table-column>
          <el-table-column label="状态" prop="name"> </el-table-column>
           <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 新增 -->
      <el-dialog
        title="创建"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >
        <div style="text-align:left;margin-bottom:1rem">
          函授站名称：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
        <div style="text-align:left;margin-bottom:1rem">
        负责人：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
         <div style="text-align:left;margin-bottom:1rem">
        联系人：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
         <div style="text-align:left;margin-bottom:1rem">
        邮箱：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
        <div style="text-align:left;margin-bottom:1rem">
        创建时间： <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间">
                </el-date-picker>
        </div>
        <div style="text-align:left;margin-bottom:1rem">
        状态：
         <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
        </div>
        <div style="text-align:right;margin-bottom:1rem"> 
            <el-button @click="dialogVisibleUpload=false">取消</el-button>
            <el-button type="primary">确认</el-button>
        </div>
      </el-dialog>
      <!-- 编辑 -->
      <el-dialog
        title="编辑"
        :visible.sync="dialogVisibleUpload1"
        width="30%"
      >
        <div style="text-align:left;margin-bottom:1rem">
          函授站名称：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
        <div style="text-align:left;margin-bottom:1rem">
        负责人：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
         <div style="text-align:left;margin-bottom:1rem">
        联系人：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
         <div style="text-align:left;margin-bottom:1rem">
        邮箱：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
        <div style="text-align:left;margin-bottom:1rem">
        创建时间： <el-date-picker
                v-model="value1"
                type="datetime"
                placeholder="选择日期时间">
                </el-date-picker>
        </div>
        <div style="text-align:left;margin-bottom:1rem">
        状态：
         <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
        </div>
        <div style="text-align:right;margin-bottom:1rem"> 
            <el-button @click="dialogVisibleUpload1=false">取消</el-button>
            <el-button type="primary">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisibleUpload1: false,
      checked: false,
      num: 1,
      dialogVisible: false,
      radio: "1",
      title: "",
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [
        {
          id: "1",
          name: "王小虎",
          num: "123",
          sex: "男",
          idCard: "3201",
          phone: "13333333333",
          address: "上海市普陀区金沙江路 1518 弄",
          memo: "备注",
        },
      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {},
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    edition() {
      this.dialogVisibleUpload = true;
    },
    editor(){
        this.dialogVisibleUpload1 = true;
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile() {},
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>