<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        考试成绩审核
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-upload2" type="primary" @click="edition()"
              >导入</el-button
            >
            <el-button icon="el-icon-refresh-right" type="primary"
              >设置等待校验</el-button
            >
            <el-button icon="el-icon-check" type="primary">设置通过</el-button>
            <el-button icon="el-icon-close" type="primary">设置错误</el-button>
            <el-button icon="el-icon-delete" type="primary">批量删除</el-button>
            <el-button icon="el-icon-delete" type="primary"
              >删除全部数据</el-button
            >
            <el-button icon="el-icon-download" type="primary">导出</el-button>
            <el-button type="primary">查询</el-button>
            <el-button>重置</el-button>
          </el-form-item>
          <div>
            <div style="width: 30%; display: inline-block">
              <el-form-item style="margin-bottom: 0">
                姓名/学号
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="学号/姓名"
                  style="width: 250px"
                ></el-input>
                <el-button
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-form-item>
            </div>
            <div style="width: 20%; display: inline-block">
              <el-form-item style="margin-bottom: 0">
                函授站
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="width: 20%; display: inline-block">
              <el-form-item style="margin-bottom: 0">
                考试批次
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="width: 20%; display: inline-block">
              <el-form-item style="margin-bottom: 0">
                校验状态
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="120" prop="id"></el-table-column>
          <el-table-column label="姓名" prop="id" width="180">
          </el-table-column>
          <el-table-column label="学号" prop="id" width="220">
          </el-table-column>
          <el-table-column label="函授站" prop="id" width="180">
          </el-table-column>
          <el-table-column label="课程名称" prop="id" width="180">
          </el-table-column>
          <el-table-column label="课程编号" prop="id" width="200">
          </el-table-column>
          <el-table-column label="录入成绩" prop="id" width="180">
          </el-table-column>
          <el-table-column label="录入人" prop="id" width="180">
          </el-table-column>
          <el-table-column label="校验状态" prop="name"> </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入 上传文件 -->
      <el-dialog
        title="上传文件"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >
        <div style="margin-bottom: 20px;text-align:left">
          <el-button type="primary">下载模板</el-button>
        </div>
        <div style="text-align:left;margin-bottom:1rem">
          年级
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="text-align:left;margin-bottom:1rem">
        层次
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
         <div style="text-align:left;margin-bottom:1rem">
        专业
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
         <div style="text-align:left;margin-bottom:1rem">
        课程
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :show-file-list="false"
          :http-request="uploadFile"
          :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
          <div class="el-upload__tip" slot="tip" style="color:red">
            导入前请先下载模板，根据模版整理好数据后上传导入.
          </div>
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      num: 1,
      dialogVisible: false,
      radio: "1",
      title: "",
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [
        {
          id: "1",
          name: "王小虎",
          num: "123",
          sex: "男",
          idCard: "3201",
          phone: "13333333333",
          address: "上海市普陀区金沙江路 1518 弄",
          memo: "备注",
        },
      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {},
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    edition() {
      this.dialogVisibleUpload = true;
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile() {},
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>