import { render, staticRenderFns } from "./Regulations.vue?vue&type=template&id=4aa53e22&scoped=true"
import script from "./Regulations.vue?vue&type=script&lang=js"
export * from "./Regulations.vue?vue&type=script&lang=js"
import style0 from "./Regulations.vue?vue&type=style&index=0&id=4aa53e22&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aa53e22",
  null
  
)

export default component.exports