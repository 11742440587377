<template>
  <div>
    <header class="header">
      <studyHead></studyHead>
      <router-view></router-view>
    </header>
    <main class="jobmain">
      <!-- 左边侧边连 -->
      <article class="left">
        <studyLeft></studyLeft>
        <router-view></router-view>
      </article>
      <!-- 右边内容 -->
      <article class="right">
        <p class="test">在线作业查询</p>
        <article class="job">
          <!-- 按钮 -->
          <section class="buttons">
            <el-button type="primary" icon="el-icon-download">导出</el-button>
            <el-button type="primary" icon="el-icon-paperclip"
              >预览作业</el-button
            >
          </section>
          <!-- form -->
          <section class="forms row">
            <section style="margin-top: 1.5rem" class="col-xs-12 buttons">
              <el-col :span="16">
                <el-input v-model="input" placeholder="请输入内容"></el-input>
              </el-col>
              <el-button
                class="searchs"
                slot="append"
                icon="el-icon-search"
              ></el-button>
              <p class="semester">学期</p>
            </section>
            <!-- 全部下拉框 -->
            <section style="margin-top: 1.5rem" class="col-xs-12 buttons">
              <el-select v-model="select" slot="prepend" placeholder="全部">
                <el-option label="第一学期" value="1"></el-option>
                <el-option label="第二学期" value="2"></el-option>
                <el-option label="第三学期" value="3"></el-option>
              </el-select>
            </section>
            <!-- 开始日期 -->
            <section style="margin-top: 1.5rem" class="col-xs-12 buttons">
              <article class="block">
                <span class="demonstration">开始日期</span>
                <el-date-picker
                  type="dates"
                  v-model="value4"
                  placeholder="选择一个或多个日期"
                  style="margin-left: 1rem"
                >
                </el-date-picker>
              </article>
            </section>
            <!-- 结束日期 -->
            <section style="margin-top: 1.5rem" class="col-xs-12 buttons">
              <article class="block">
                <span class="demonstration">结束日期</span>
                <el-date-picker
                  type="dates"
                  v-model="value5"
                  placeholder="选择一个或多个日期"
                  style="margin-left: 1rem"
                >
                </el-date-picker>
              </article>
            </section>
          </section>
          <!-- 表格 -->
          <section class="tables">
             <el-table
             border
    ref="singleTable"
    :data="tableData"
    highlight-current-row
    :default-sort = "{prop: 'date', order: 'descending'}"
    @current-change="handleCurrentChange"
    style="width: 100%">
    <!-- 序号 -->
    <el-table-column
      type="index"
         label="序号"
      width="50">
    </el-table-column>
    <!-- 学号 -->
    <el-table-column
      property="sudent"
      sortable
      label="学号"
      width="120">
    </el-table-column>
    <!-- 学生姓名 -->
    <el-table-column
      property="name"
      label="学生姓名"
      sortable
      width="120">
    </el-table-column>
    <!-- 学期 -->
     <el-table-column
      property="semester"
      label="学期"
      sortable
      width="100">
    </el-table-column>
      <!-- 课程名称 -->
     <el-table-column
      property="course"
      label="课程名称"
      sortable
      width="120">
    </el-table-column>
      <!-- 课程名称 -->
     <el-table-column
      property="coursenumber"
      label="课程编号"
      sortable
      width="120">
    </el-table-column>
          <!-- 作业名称 -->
     <el-table-column
      property="job"
      label="作业名称"
      sortable
      width="150">
    </el-table-column>
              <!-- 作答时间 -->
     <el-table-column
      property="answer"
      label="作答时间"
      sortable
      width="200"
      class='dian'
      >
    </el-table-column>
              <!-- 用时 -->
     <el-table-column
      property="hack"
      label="用时"
      sortable
      width="120">
    </el-table-column>
              <!-- 提交方式 -->
     <el-table-column
      property="way"
      label="提交方式"
      sortable
      width="150">
    </el-table-column>
              <!-- 浏览器 -->
     <el-table-column
      property="browser"
      label="浏览器"
      sortable
      width="120">
    </el-table-column>
              <!-- 浏览器版本 -->
     <el-table-column
      property="explorer"
      label="浏览器版本"
      sortable
      width="120">
    </el-table-column>
              <!-- 操作系统 -->
     <el-table-column
      property="semester"
      label="操作系统"
      sortable
      width="120">
    </el-table-column>
  </el-table>
          </section>
          <!-- 分页 -->
          <section class="paging">
  <div class="block">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleChange"
      :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="400">
    </el-pagination>
  </div>
          </section>
        </article>
      </article>
    </main>
  </div>
</template>
<script>
import studyHead from "@/components/common/head.vue";
import studyLeft from "@/components/common/left.vue";
// //import "bootstrap/dist/css/bootstrap.min.css";
// //import "bootstrap/dist/js/bootstrap.min.js";
export default {
  components: {
    studyHead,
    studyLeft,
  },
  data() {
    return {
      input: "", //输入框
      select: "", //下拉框
      value4: "", //开始日期
      value5: "", //结束日期
       currentPage1: 5,
        currentPage2: 5,
        currentPage3: 5,
        currentPage4: 4,
            tableData: [{
          sudent: '2020174',
          name: '王小虎',
          semester:1,//学期
          course:'思想道德与法律',//课程名称
          coursenumber:'8428',//课程编号
          job:'2020级上半学期',// 作业名称
          answer:'2021-03-25 09:26:54',//作答时间
          hack:'24小时',//用时
          way:'未答完手动提交',//提交方式
          browser:'FireFox8',//浏览器
          explorer:'86.0',//浏览器版本
          operating:'系统'//操作系统
        }, 
        {
          sudent: '2020174',
          name: '王小虎',
          semester:1,//学期
          course:'思想道德与法律',//课程名称
          coursenumber:'8428',//课程编号
          job:'2020级上半学期',// 作业名称
          answer:'2021-03-25 09:26:54',//作答时间
          hack:'24小时',//用时
          way:'未答完手动提交',//提交方式
          browser:'FireFox8',//浏览器
          explorer:'86.0',//浏览器版本
          operating:'系统'//操作系统
        },
        {
          sudent: '2020174',
          name: '王小虎',
          semester:1,//学期
          course:'思想道德与法律',//课程名称
          coursenumber:'8428',//课程编号
          job:'2020级上半学期',// 作业名称
          answer:'2021-03-25 09:26:54',//作答时间
          hack:'24小时',//用时
          way:'未答完手动提交',//提交方式
          browser:'FireFox8',//浏览器
          explorer:'86.0',//浏览器版本
          operating:'系统'//操作系统
        },
        {
          sudent: '2020174',
          name: '王小虎',
          semester:1,//学期
          course:'思想道德与法律',//课程名称
          coursenumber:'8428',//课程编号
          job:'2020级上半学期',// 作业名称
          answer:'2021-03-25 09:26:54',//作答时间
          hack:'24小时',//用时
          way:'未答完手动提交',//提交方式
          browser:'FireFox8',//浏览器
          explorer:'86.0',//浏览器版本
          operating:'系统'//操作系统
        },
        {
          sudent: '2020174',
          name: '王小虎',
          semester:1,//学期
          course:'思想道德与法律',//课程名称
          coursenumber:'8428',//课程编号
          job:'2020级上半学期',// 作业名称
          answer:'2021-03-25 09:26:54',//作答时间
          hack:'24小时',//用时
          way:'未答完手动提交',//提交方式
          browser:'FireFox8',//浏览器
          explorer:'86.0',//浏览器版本
          operating:'系统'//操作系统
        },
        ],//表格
         currentRow: null
    };
  },
  methods: {
     handleCurrentChange(val) {
        this.currentRow = val;
      },
         handleSizeChange(val) {
      },
      handleChange(val) {
      }
  },
};
</script>
<style scoped>
html {
  font-size: calc(100vw / 7.5);
}
.jobmain {
  display: flex;
}
/* 右边 */
.right {
  width: 100%;
  padding: 0.1rem;
}
/* 标题 */
.test {
  text-align: left;
  font-size: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 0.2rem;
  border-bottom: 0.01rem solid rgb(221, 221, 221);
}
/* 按钮 */
.buttons {
  display: flex;
}
/* form表单 */
.forms {
  display: flex;
}
.forms section {
  width: 25%;
}
/* 学期 */
.semester {
  font-size: 1rem;
  margin-bottom: 0;
  padding: 0.5rem;
}
.searchs {
  margin-right: 1.3rem;
}
/* 开始日期 */
.block {
  margin-left: 1rem;
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}
/* 表格 */
.tables{
  margin-top: 1rem;
}
/* 多余内容呈现... */
.dian{
 text-overflow:ellipsis;
      white-space:nowrap;
      overflow:hidden;
      -webkit-line-clamp:3;
      -webkit-box-orient:vertical;
}
/* 分页 */
.paging{
margin-top: 1rem;
width: 100%;
overflow-x: scroll;
display: flex;

}
.paging::-webkit-scrollbar{
  width: 0;
  height: 0;
}


@media (max-width: 75rem) {
  .left {
    display: none;
  }
  .rigtht {
    width: 100%;
  }
  .forms section {
    width: 100%;
  }
  .row {
    margin-left: -15px;
  }
}
</style>