<template>
  <div class="big">
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <div class="main">
      <Left></Left>
      <router-view></router-view>
      <div class="mainle">
        <div>
          <img src="@/assets/xuanchuan.jpg" alt />
        </div>
        <div style="margin-top:0.2rem;" class="clearfix">
          <h3 class="ampanel">学习必备</h3>
          <div style="text-align:left;">
            <router-link to="/Alliance/Learningmethod">
              <el-button type="primary" style="margin-top:0.13rem;">
                <i class="el-icon-video-camera"></i>
                学习方法
              </el-button>
            </router-link>
            <router-link to="/Alliance/Payment" style="margin-left:0.1rem">
              <el-button type="warning" style="margin-top:0.13rem;">
                <i class="el-icon-edit-outline"></i>
                缴费说明
              </el-button>
            </router-link>
            <router-link to="/Alliance/Regulations">
              <el-button type="success" style="margin-top:0.13rem;margin-left:0">
                <i class="el-icon-office-building"></i>
                规章制度
              </el-button>
            </router-link>
            <router-link to="/Alliance/Commonproblem" style="margin-left:0.1rem">
              <el-button>
                <i class="el-icon-question"></i>
                常见问题
              </el-button>
            </router-link>
          </div>
        </div>
        <div style="margin-top:0.2rem;" class="clearfix">
          <h3 class="ampanel">教学支持服务</h3>
          <div style="text-align:left;">
            <el-button type="success" style="margin-top:0.13rem;margin-left:0">
              <i class="el-icon-chat-round"></i>
              自习室
            </el-button>
            <router-link to="/Alliance/MessageBoard" style="margin-left:0.1rem">
              <el-button type="warning" style="margin-top:0.13rem;">
                <i class="el-icon-edit-outline"></i>
                留言板
              </el-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="mainright">
        <div style="border-color: #ddd;">
          <div class="clearfix">
            <h3 class="ampanel">mrlu，欢迎您进入工作室！今天是 2020年03月23日</h3>
          </div>
          <div class="clearfix">
            <div style="text-align:left;">
              <div class="am-u-sm-3">
                <img src="@/assets/nophoto.png" alt />
              </div>
              <div class="am-u-sm-4">
                <p style="margin-bottom:0.03rem;">你好，mulu同学！</p>
                <p style="margin-bottom:0.03rem;">学号：mulu</p>
                <p style="margin-bottom:0.03rem;">年级：自由注册年级</p>
                <p style="margin-bottom:0.03rem;">层次：自由注册层次</p>
                <p style="margin-bottom:0.03rem;">：自由注册专业</p>
                <p style="margin-bottom:0.03rem;">学习中心：自由注册学习中心</p>
                <p style="margin-bottom:0.15rem;">学习中心联系电话：~</p>
                <router-link to="/Alliance/ToPay" style="margin-right:0.1rem">
                  <el-button type="primary">财务信息</el-button>
                </router-link>
                <router-link to="/Alliance/Personal">
                  <el-button type="primary" class="aa">个人信息</el-button>
                </router-link>
              </div>
              <div class="am-u-sm-5">
                <p style="margin-bottom:0.03rem;">星级：星星</p>
                <p>
                  个人签名：
                  <el-input v-model="input" placeholder="编辑个人签名"></el-input>
                </p>
              </div>
            </div>
            <div class="am-u-sm-6">
              <p style="margin-bottom:0.1rem;">总课程</p>
              <p>门课程</p>
            </div>
            <div class="am-u-sm-6">
              <p style="margin-bottom:0.1rem;">必修课</p>
              <p>门课程</p>
            </div>
            <div class="am-u-sm-7">
              <p style="margin-bottom:0.1rem;">已通过</p>
              <p>0 门课程</p>
            </div>
          </div>
          <div class="clearfix">
            <h3 class="ampanel">
              <i class="el-icon-bell"></i>
              通知公告
            </h3>
          </div>
          <div class="clearfix">
            <h3 class="ampanel">
              <i class="el-icon-date"></i>
              我的课程
            </h3>
            <div>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="在修课程" name="first">在修课程</el-tab-pane>
                <el-tab-pane label="已修完课程" name="second">已修完课程</el-tab-pane>
                <el-tab-pane label="修完未通过" name="third">修完未通过</el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <div class="am-panel"></div>
      </div>
    </div>
    <!-- <div>
        <Footer></Footer>
        <router-view></router-view>
    </div>-->
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.mainright {
  width: 52%;
  border-color: #ddd;
  margin-left: 0.4rem;
  float: right;
}
.clearfix {
  color: #444;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 0.15rem;
  margin-bottom: 0.2rem;
}
.am-panel {
  padding: 0.2rem;
}
.am-u-sm-3 {
  display: inline-block;
  width: 19%;
  float: left;
}
.am-u-sm-4 {
  width: 36%;
  margin-left: 0.3rem;
  font-size: 0.14rem;
  display: inline-block;
}
.am-u-sm-5 {
  width: 36%;
  margin-left: 0.2rem;
  font-size: 0.14rem;
  float: right;
}
.am-u-sm-6 {
  width: 25%;
  display: inline-block;
  font-size: 0.14rem;
  margin-top: 0.18rem;
  border-right: 1px solid #d6d6d6;
}
.am-u-sm-7 {
  width: 30%;
  display: inline-block;
  font-size: 0.14rem;
  margin-top: 0.18rem;
}
.el-button--primary {
  color: #fff;
  background-color: #0e90d2;
  border-color: #0e90d2;
}
.am-u-sm-3 img {
  width: 100%;
  display: inline-block;
}
.ampanel {
  font-size: 0.14rem;
  text-align: left;
  font-weight: 500;
}
.mainle {
  width: 23%;
  margin-left: 0.4rem;
  float: right;
}
.mainle img {
  width: 100%;
}
.amtext {
  display: inline-block;
  float: right;
}
.amtext button {
  padding-left: 0.1rem;
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
  padding-right: 0.1rem;
  background-color: #77b723;
  border: #77b723;
  color: #fff;
  border-radius: 0.05rem;
  margin-left: 0.1rem;
}
.amform {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.12rem;
  text-align: left;
}
.el-button--success {
  background-color: #77b723;
  border-color: #77b723;
}
.el-button--text {
  color: #77b723;
}
.el-pagination {
  white-space: normal;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
@media only screen and (max-width: 1100px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-left {
    width: 100% !important;
    margin-bottom: 0.2rem !important;
  }
  .mainle {
    width: 100% !important;
    float: none !important;
    margin-left: 0 !important;
  }
  .am-u-sm-6 {
    width: 23% !important;
  }
  .aa {
    margin-left: 0 !important;
  }
  .mainright {
    width: 100% !important;
  }
  .el-menu-item {
    font-size: 0.3rem !important;
  }
  .header {
    font-size: 0.3rem !important;
    padding: 0.4rem;
  }
  .dp[data-v-1aa8c6e6] {
    display: inline-block;
    font-size: 0.3rem;
    float: left;
    margin-top: 0.09rem;
  }
  .sou[data-v-1aa8c6e6] {
    width: 100%;
  }
  .neirong[data-v-1aa8c6e6] {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}

.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>
<script>
import Header from "@/components/Backstage";
import Left from "@/components/Backlf";
// import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Left
    // Footer
  },
  data() {
    return {
      input: "",
      activeName: "first",
      currentPage1: 4,
      currentPage3: 2,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      }
    };
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
    onSubmit() {
      this.dialogVisible = false;
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    handleClick(tab, event) {
    }
  }
};
</script>


