<template>
  <div>


    <div>
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
        注册信息统计
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary"
              >导出</el-button
            >
            <el-button type="primary"
              >查询</el-button
            >
            <el-button
              >重置</el-button
            >
          </el-form-item>
          <div>
          <el-form-item style="margin-bottom:0;margin-left:2rem">
            年级
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
           <el-form-item style="margin-bottom:0;margin-left:2rem">
            层次
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
           <el-form-item style="margin-bottom:0;margin-left:2rem">
            函授站
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
           <el-form-item style="margin-bottom:0;margin-left:2rem">
            专业
            <el-select v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData"  border>
            <el-table-column label="序号" width="150" type="index"></el-table-column>
          <el-table-column label="函授站" prop="id">
          </el-table-column>
          <el-table-column label="专业" prop="name"> </el-table-column>
          <el-table-column label="层次" prop="name" > </el-table-column>
          <el-table-column label="录取人数" prop="num" > </el-table-column>
          <el-table-column label="已注册人数" prop="sex">
          </el-table-column>
          
          <!-- <el-table-column label="专业" prop="sex" width="230"> </el-table-column> -->
          <el-table-column label="未注册人数" prop="sex" width="230"> </el-table-column>
          <el-table-column label="录取数" prop="sex">
          </el-table-column>
          <!-- <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small"
                >绑定角色</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      total: 0,
      pageNum: 1,
      num:1,
      input:"",
      pageSize: 10,
      textarea: "",
      radio:'1',
      title: "",
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [
        {
          id: "1",
          name: "王小虎",
          num: "123",
          sex: "男",
          idCard: "3201",
          phone: "13333333333",
          address: "上海市普陀区金沙江路 1518 弄",
          memo: "备注",
        },
      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
       pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        value1: '',
    };
  },
  methods: {
    handleSearch() {},
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content{
  text-align: left;
}
</style>