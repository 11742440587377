<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        场次管理
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary">导出</el-button>
            <el-button icon="el-icon-edit" type="primary" @click="Batch()"
              >添加</el-button
            >
            <!-- <el-button icon="el-icon-setting" type="primary" @click="reshuffle()"
              >设置学籍异动（层次和专业）</el-button
            > -->
            <!-- <el-button icon="el-icon-check" type="primary">审核</el-button> -->
            <!-- <el-button icon="el-icon-close" type="primary">驳回</el-button> -->
          </el-form-item>
          <div>
            <el-form-item label="场次编号/场次名称" style="margin-bottom: 0">
              <el-input
                v-model="search.name"
                clearable
                @input="() => (search.name = search.name.trim())"
                placeholder="请输入内容"
                style="width: 200px"
              ></el-input>
              <el-button
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="场次编号" prop="name">
          </el-table-column>
          <el-table-column label="场次名称" prop="name">
          </el-table-column>
          <el-table-column label="函授站" prop="sex">
          </el-table-column>
          <el-table-column label="操作" width="130" fixed="right">
            <template slot-scope="scope">
                 <el-button type="primary" @click="reshuffle(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 创建 -->
      <el-dialog
        title="创建"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >
        <div style="margin-bottom: 20px; text-align: left">
            场次编号：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
           场次名称：<el-input v-model="input" placeholder="请输入内容"  style="width:70%"></el-input>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
        场次描述：
          <el-input
          style="width:70%"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="textarea">
            </el-input>
        </div>
        <div style="margin-bottom: 20px;">
           <el-button @click="dialogVisibleUpload=false">取消</el-button>
            <el-button type="primary">确认</el-button>
        </div>
      </el-dialog>
      <!-- 编辑 -->
       <el-dialog
        title="编辑"
        :visible.sync="dialogVisible1"
        width="30%"
      >
        <!-- <div style="margin-bottom: 20px; text-align: left">
          <el-button type="primary" @click="dialogVisible2=false">取消</el-button>
          <el-button type="primary"
            >设置休学</el-button
          >
        </div> -->
        <div style="margin-bottom: 20px; text-align: left">
            场次编号：<el-input v-model="input" placeholder="请输入内容" style="width:70%"></el-input>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
           场次名称：<el-input v-model="input" placeholder="请输入内容"  style="width:70%"></el-input>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
        场次描述：
          <el-input
          style="width:70%"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="textarea">
            </el-input>
        </div>
        <div style="margin-bottom: 20px;">
           <el-button @click="dialogVisible1=false">取消</el-button>
            <el-button type="primary">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      radio: "1",
      title: "",
      dialogVisibleUpload: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [
        {
          id: "1",
          name: "王小虎",
          num: "123",
          sex: "男",
          idCard: "3201",
          phone: "13333333333",
          address: "上海市普陀区金沙江路 1518 弄",
          memo: "备注",
        },
      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {},
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    //批量上传图片
    Batch() {
      this.dialogVisibleUpload = true;
    },
    reshuffle(){
        this.dialogVisible1 = true;
    }
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
</style>